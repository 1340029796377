import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "73px 10.8% 0 10.8%",
  [theme.breakpoints.down("md")]: {
    padding: "73px 4.3% 0 4.3%",
  },
  "@media (max-width: 1200px)": {
    paddingLeft: "3%",
    paddingRight: "3%",
  },
}));

export default StyledBox;
