import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Box, ThemeProvider } from "@mui/material";

import Home from "./pages/home";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Security from "./pages/security";
import Header from "./components/header";
import Footer from "./components/footer";
import Cookies from "./components/popups/cookies";

import ScrollToTop from "./components/scroll_to_top";

import { cookiesAccepted, setCookiesAccepted } from "./storage/sessionStorage";
import { theme } from "./utils/theme";
import CIO from "./pages/cio";
import Product from "./pages/product";
import Procurement from "./pages/procurement";
import { init as initFullStory } from "@fullstory/browser";

function App() {
  const [isShowCookiesPopup, setIsShowCookiesPopup] = useState(
    cookiesAccepted === null
  );
  const [trackingInitialized, setTrackingInitialized] = useState(false);

  const initTracking = () => {
    if (!trackingInitialized) {
      setTrackingInitialized(true);
      initFullStory({ orgId: 'o-1SEWKG-na1' });
    }
  };

  useEffect(() => {
    if (cookiesAccepted === 'true') {
      initTracking();
    }
  });

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <Box sx={{ fontFamily: "CoFo Sans" }}>
          <Header />

          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path={"/procurement"} element={<Procurement />} />
            <Route path={"/cio"} element={<CIO />} />
            <Route path={"/product"} element={<Product />} />
            <Route path={"/terms"} element={<Terms />} />
            <Route path={"/privacy"} element={<Privacy />} />
            <Route path={"/security"} element={<Security />} />
          </Routes>
          {isShowCookiesPopup && (
            <Cookies
              isOpen={isShowCookiesPopup}
              close={(accepted) => {
                setIsShowCookiesPopup(false);
                setCookiesAccepted(accepted);
                if (accepted) {
                  initTracking();
                }
              }}
            />
          )}
          <Footer />
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
