import React, { useEffect, useState } from "react";

import { Box, Modal, SwipeableDrawer, useTheme } from "@mui/material";

import RequestDemoForm from "./requestDemoForm";
import RequestedSuccess from "./requestedSuccess";

import { isMobileDevice } from "../../utils/deviceUtils";

const RequestDemo = ({ isOpen, close }) => {
  const theme = useTheme();

  const [view, setView] = useState("form");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsMobile(isMobileDevice(userAgent));
  }, []);

  const viewPage = () => {
    switch (view) {
      case "form":
        return (
          <RequestDemoForm close={close} isOpen={isOpen} setView={setView} />
        );
      case "success" || "error":
        return (
          <RequestedSuccess close={close} setView={setView} variant={view} />
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (isOpen) {
      setView("form");
    }
  }, [isOpen]);

  if (isMobile) {
    return (
      <SwipeableDrawer
        anchor={"bottom"}
        open={isOpen}
        onClose={close}
        onOpen={() => {}}
        slotProps={{
          backdrop: {
            sx: {
              background: "rgba(0,0,0,0.25)",
            },
          },
        }}
        PaperProps={{
          sx: {
            boxShadow: "none",
            background: theme.palette.background.main,
            borderRadius: "20px 20px 0 0",
            paddingBottom: "24px",
            maxHeight: window.innerHeight < 528 ? "calc(100% - 80px)" : "504px",
          },
        }}
      >
        {viewPage()}
      </SwipeableDrawer>
    );
  }

  return (
    <Modal
      disableScrollLock={true}
      open={isOpen}
      onClose={close}
      slotProps={{
        root: {
          sx: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        },
        backdrop: {
          sx: {
            background: "rgba(0,0,0,0.25)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        },
      }}
    >
      <Box
        sx={{
          boxShadow: "none",
          background: theme.palette.background.main,
          borderRadius: "20px",
          width: view === "error" ? "fit-content" : "346px",
        }}
      >
        {viewPage()}
      </Box>
    </Modal>
  );
};

export default RequestDemo;
