import React from "react";
import { Helmet } from "react-helmet";

import { Box, Link, Stack, useTheme } from "@mui/material";

const styles = {
  title: { fontSize: "24px" },
  ul: { fontSize: "16px" },
};
export default function Security() {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>PartnerElement - Security</title>
        <link rel="icon" href="/favicon.ico" sizes="any" />
      </Helmet>
      <Box
        sx={{
          width: "100%",
          background: "#F5F4F2",
          minHeight: "100vh",
          color: theme.palette.text.neutral,
          fontFamily: "CoFo Sans",
          fontSize: "18px",
          ul: {
            margin: "0",
            padding: "0 0 0 20px",
            li: {
              marginBottom: "4px",
              "last-of-type": { marginBottom: "0" },
            },
          },
        }}
      >
        <Box
          sx={{
            width: "calc(50% - 10.8%)",
            padding: "120px 10.8% 228px 10.8%",
            "@media (max-width: 1240px)": {
              width: "calc(75% - 4.3%)",
              padding: "120px 4.3% 228px 4.3%",
            },
            "@media (max-width: 800px)": {
              width: "calc(100% - 8.6%)",
              padding: "120px 4.3% 228px 4.3%",
            },
          }}
        >
          <Box
            sx={{ fontSize: "40px", fontWeight: "400", marginBottom: "36px" }}
          >
            Security
          </Box>
          <Stack
            direction={"column"}
            gap={"24px"}
            sx={{ div: { wordBreak: "break-word" } }}
          >
            <Box>
              Security of PartnerElement customers’ data is our core concern. We
              are committed to establishing and maintaining a robust operational
              environment that meets and exceeds the security, availability,
              confidentiality, and privacy commitments made to our customers.
              PartnerElement maintains several security, risk, and compliance
              initiatives and has an ongoing commitment to extend its security
              and privacy credentials continuously.
            </Box>
            <Box sx={styles.title}>Third-party Data Policy Compliance</Box>
            <Box component={"ul"} sx={styles.ul}>
              <Box component={"li"}>
                Google API Services
                <Box component={"ul"}>
                  <Box component={"li"}>
                    PartnerElement's use and transfer of information
                    received from Google APIs to any other app will
                    adhere to <Link
                      href="https://developers.google.com/terms/api-services-user-data-policy"
                      target={"_blank"}
                      rel="noreferrer"
                    >Google API Services User Data Policy</Link>, including the Limited Use
                    requirements.
                  </Box>
                </Box>
              </Box>
            </Box>
            

            <Box sx={styles.title}>Organizational Security</Box>
            <Box component={"ul"} sx={styles.ul}>
              <Box component={"li"}>
                Information Security Program
                <Box component={"ul"}>
                  <Box component={"li"}>
                    We have an Information Security Program in place that is
                    communicated throughout the organization. Our Information
                    Security Program follows the criteria set forth by the SOC 2
                    Framework. PartnerElement is SOC2 Type 2 certified.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Third-Party Audits
                <Box component={"ul"}>
                  <Box component={"li"}>
                    Our organization undergoes annual independent third-party
                    assessments to test our security and compliance controls.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Roles and Responsibilities
                <Box component={"ul"}>
                  <Box component={"li"}>
                    Roles and responsibilities related to our Information
                    Security Program and protecting customer data are
                    well-defined and documented. Our team members are required
                    to review and accept all of the security policies.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Security Awareness Training
                <Box component={"ul"}>
                  <Box component={"li"}>
                    Our team members are required to go through employee
                    security awareness training covering industry standard
                    practices and information security topics such as phishing
                    and password management.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Confidentiality
                <Box component={"ul"}>
                  <Box component={"li"}>
                    All team members are required to sign and adhere to an
                    industry-standard confidentiality agreement prior to their
                    first day of work.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Background Checks
                <Box component={"ul"}>
                  <Box component={"li"}>
                    We perform background checks on all new employees in
                    accordance with local laws.
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={styles.title}>Cloud Security</Box>
            <Box component={"ul"} sx={styles.ul}>
              <Box component={"li"}>
                Cloud Infrastructure Security
                <Box component={"ul"}>
                  <Box component={"li"}>
                    All of our services are hosted with Amazon Web Services
                    (AWS). For more information on our provider’s security
                    processes, please visit{" "}
                    <Box
                      sx={{ textDecoration: "underline" }}
                      component={"a"}
                      href="https://aws.amazon.com/security/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      AWS Security
                    </Box>
                    .
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Data Hosting Security
                <Box component={"ul"}>
                  <Box component={"li"}>
                    All of our data is hosted on Amazon Web Services (AWS)
                    databases. These databases are all located in the United
                    States. Please visit{" "}
                    <Box
                      sx={{ textDecoration: "underline" }}
                      component={"a"}
                      href="https://aws.amazon.com/security/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      AWS Security
                    </Box>{" "}
                    for more information.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Encryption at Rest
                <Box component={"ul"}>
                  <Box component={"li"}>
                    All databases are encrypted at rest.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Encryption in Transit
                <Box component={"ul"}>
                  <Box component={"li"}>
                    Our applications encrypt in transit with TLS/SSL only.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Vulnerability Scanning
                <Box component={"ul"}>
                  <Box component={"li"}>
                    We perform vulnerability scanning and actively monitor for
                    threats.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Logging and Monitoring
                <Box component={"ul"}>
                  <Box component={"li"}>
                    We actively monitor and log various cloud services.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Business Continuity and Disaster Recovery
                <Box component={"ul"}>
                  <Box component={"li"}>
                    We use our data hosting provider’s backup services to reduce
                    any risk of data loss in the event of a hardware failure. We
                    utilize monitoring services to alert the team in the event
                    of any failures affecting users.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Incident Response
                <Box component={"ul"}>
                  <Box component={"li"}>
                    We have a process for handling information security events
                    which includes escalation procedures, rapid mitigation, and
                    communication.
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={styles.title}>Access Security</Box>
            <Box component={"ul"} sx={styles.ul}>
              <Box component={"li"}>
                Permissions and Authentication
                <Box component={"ul"}>
                  <Box component={"li"}>
                    Access to cloud infrastructure and other sensitive tools is
                    limited to authorized employees who require it for their
                    roles.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Where available, we have Single Sign-on (SSO), 2-factor
                authentication (2FA), and strong password policies to ensure
                access to cloud services is protected.
              </Box>
              <Box component={"li"}>
                Least Privilege Access Control
                <Box component={"ul"}>
                  <Box component={"li"}>
                    We follow the principle of least privilege with respect to
                    identity and access management.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Quarterly Access Reviews
                <Box component={"ul"}>
                  <Box component={"li"}>
                    We perform quarterly access reviews of all team members with
                    access to sensitive systems.
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={styles.title}>Vendor and Risk Management</Box>
            <Box component={"ul"} sx={styles.ul}>
              <Box component={"li"}>
                Annual Risk Assessments
                <Box component={"ul"}>
                  <Box component={"li"}>
                    We undergo at least annual risk assessments to identify
                    potential threats, including considerations for fraud.
                  </Box>
                </Box>
              </Box>
              <Box component={"li"}>
                Vendor Risk Management
                <Box component={"ul"}>
                  <Box component={"li"}>
                    Vendor risk is determined, and the appropriate vendor
                    reviews are performed prior to authorizing a new vendor.
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>Contact Us</Box>
              <Box>
                If you have any questions, comments, or concerns, or if you wish
                to report a potential security issue, please contact
                security@partnerelement.com.
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
