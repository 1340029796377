// MobileMenu.js
import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const MobileMenu = ({
  isOpen,
  toggleDrawer,
  pages,
  urls,
  productFeatures,
  featuresUrls,
}) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    toggleDrawer(false);
    navigate(path);
  };

  return (
    <Drawer
      anchor={"top"}
      open={isOpen}
      onClose={() => toggleDrawer(false)}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: "auto",
          borderRadius: "0 0 20px 20px",
          backgroundColor: "rgba(245, 244, 242, 1)",
        },
      }}
    >
      <IconButton
        onClick={() => toggleDrawer(false)}
        sx={{ position: "absolute", top: 5, right: 10 }}
      >
        <CloseIcon />
      </IconButton>
      <Box padding={"30px"}>
        <List>
          {pages.map((text, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                key={text}
                onClick={() => handleNavigation(urls[index])}
              >
                <ListItemText>
                  <Typography
                    fontFamily={"CoFo Sans"}
                    fontWeight={"900"}
                    fontSize={"16px"}
                    lineHeight={"20px"}
                    letterSpacing={"0"}
                  >
                    {text}
                  </Typography>
                </ListItemText>
              </ListItem>
              <Box paddingLeft={"16px"} paddingRight={"16px"}>
                <Box sx={{ borderBottom: "1px solid #A7A4E0" }}></Box>
              </Box>
            </React.Fragment>
          ))}
          <ListItem button onClick={() => handleNavigation("/product")}>
            <ListItemText>
              <Typography
                fontFamily={"CoFo Sans"}
                fontWeight={"900"}
                fontSize={"16px"}
                lineHeight={"20px"}
                letterSpacing={"0"}
              >
                Product
              </Typography>
            </ListItemText>
          </ListItem>
          {productFeatures.map((feature, index) => (
            <ListItem
              button
              key={feature}
              onClick={() => handleNavigation(featuresUrls[index])}
            >
              <ListItemText>
                <Typography fontFamily={"CoFo Sans"} fontSize={"16px"} lineHeight={"20px"} letterSpacing={"0"}>
                  {feature}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default MobileMenu;
