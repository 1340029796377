import React, { useEffect, useState } from "react";

import { Box, IconButton, Stack, useTheme } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

import StyledButton from "../styled/styledButton";

import { isMobileDevice } from "../../utils/deviceUtils";

const RequestedSuccess = ({ close, variant }) => {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsMobile(isMobileDevice(userAgent));
  }, []);

  return (
    <Box
      sx={{
        fontFamily: "CoFo Sans",
        overflow: "auto",
        height: "calc(100% - 24px)",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"end"}
        sx={{ paddingTop: "28px", paddingRight: "28px" }}
      >
        <IconButton
          sx={{
            padding: "4px",
            borderRadius: "5px",
            background: "transparent",
            color: "#665A56",
            ":hover": { background: "rgba(56, 47, 45, 0.05)" },
            ":focus": { background: "rgba(56, 47, 45, 0.05)" },
            span: { display: "none" },
          }}
          onClick={close}
        >
          <CloseRounded sx={{ color: "#665A56", fontSize: "16px" }} />
        </IconButton>
      </Stack>
      <Box sx={{ padding: "0 40px 40px 40px" }}>
        <Stack direction={"column"} gap={"24px"}>
          <Box
            sx={{
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "24px",
              color: theme.palette.text.neutral,
              marginBottom: isMobile && "calc(100% - 130px)",
            }}
          >
            {variant === "success" ? (
              <>
                <Box>Thank you!</Box>
                <Box>We’ll be in touch soon.</Box>
              </>
            ) : (
              <Stack
                direction={"column"}
                justifyContent={"start"}
                alignItems={"start"}
                gap={"12px"}
              >
                <Box>Something went wrong. We are sorry.</Box>
                <Box>
                  <Box>
                    Please email{" "}
                    <Box
                      sx={{ textDecoration: "underline" }}
                      component={"a"}
                      href="mailto:privacy@partnerelement.com"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      sales@partnerelement.com
                    </Box>{" "}
                  </Box>
                  <Box>to see the demo.</Box>
                </Box>
              </Stack>
            )}
          </Box>

          <Box width={"100%"}>
            <StyledButton
              sx={{
                width: "100%",
                height: "40px",
                padding: "12px 30px",
                fontSize: "16px",
              }}
              color={"buttonPrimary"}
              variant={"contained"}
              label={"Got it"}
              onClick={() => {
                close();
              }}
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default RequestedSuccess;
