import React from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Box, useTheme } from "@mui/material";

import StyledButton from "./styled/styledButton";
import { isMobileDevice } from "../utils/deviceUtils";

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const onClickStatus = () => {
    const loginButton = document.createElement("a");
    loginButton.href = "https://partnerelement.statuspage.io/";
    loginButton.click();
  };

  return (
    <>
      <Stack
        direction={"row"}
        gap={"8px"}
        alignItems={"center"}
        sx={{
          padding: "0 10.8% 28px 10.8%",
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "16px",
          height: "96px",
          backgroundColor: theme.palette.footer.backgroundColor,
          color: theme.palette.footer.fontColor,
          justifyContent: "space-between",
          button: {
            color: theme.palette.text.medium,
            fontSize: "12px",
            padding: "4px 10px",
            lineHeight: "15px",
            whiteSpace: "nowrap",
            minWidth: "0px",
          },
          "@media (max-width: 786px)": {
            flexDirection: "row",
            padding: "0 7px 28px 7px",
          },
          "@media (max-width: 450px)": {
            flexDirection: "column",
            alignItems: "normal",
          },
        }}
      >
        <Box
          sx={{
            "@media (max-width: 786px)": {
              marginBottom: "4px",
            },
            "@media (max-width: 600px)": {
              marginTop: "15px",
              marginLeft: "calc(5% + 8px)",
            },
            "@media (max-width: 400px)": {
              marginTop: "15px",
              marginLeft: "calc(5% + 8px)",
            },
          }}
        >
          2024 PartnerElement Inc.
          <br />
          Contact us:{" "}
          <a
            style={{ color: theme.palette.footer.fontColor }}
            href="mailto:support@partnerelement.com"
          >
            support@partnerelement.com
          </a>
        </Box>
        <Stack
          direction={"row"}
          gap={"8px"}
          alignItems={"center"}
          flexWrap={"wrap"}
          justifyContent={isMobileDevice ? "flex-start" : "center"}
          paddingLeft={isMobileDevice ? "5%" : "0"}
        >
          <StyledButton
            onClick={() => navigate("/terms")}
            variant={"text"}
            size={"small"}
            color={"buttonTextFooter"}
            label={"Terms of service"}
            sx={{ width: "107px" }}
          />
          <StyledButton
            onClick={() => navigate("/privacy")}
            variant={"text"}
            size={"small"}
            color={"buttonTextFooter"}
            label={"Privacy policy"}
            sx={{ width: "93px" }}
          />
          <StyledButton
            onClick={() => navigate("/security")}
            variant={"text"}
            size={"small"}
            color={"buttonTextFooter"}
            label={"Security"}
            sx={{ width: "65px" }}
          />
          <StyledButton
            onClick={onClickStatus}
            variant={"text"}
            size={"small"}
            color={"buttonTextFooter"}
            label={"Status"}
            sx={{ width: "56px" }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default Footer;
