import React from "react";
import { Helmet } from "react-helmet";

import { Box, Stack, useTheme } from "@mui/material";

const styles = {
  title: { fontSize: "24px" },
  ul: { fontSize: "16px" },
};
export default function Privacy() {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>PartnerElement - Privacy Policy</title>
        <link rel="icon" href="/favicon.ico" sizes="any" />
      </Helmet>
      <Box
        sx={{
          width: "100%",
          background: "#F5F4F2",
          minHeight: "100vh",
          color: theme.palette.text.neutral,
          fontFamily: "CoFo Sans",
          fontSize: "18px",
          ul: {
            margin: "0",
            padding: "0 0 0 20px",
            li: {
              marginBottom: "4px",
              "last-of-type": { marginBottom: "0" },
            },
          },
          ol: {
            margin: "0",
            padding: "0 0 0 20px",
            li: {
              marginBottom: "4px",
              "last-of-type": { marginBottom: "0" },
            },
          },
        }}
      >
        <Box
          sx={{
            width: "calc(50% - 10.8%)",
            padding: "120px 10.8% 228px 10.8%",
            "@media (max-width: 1240px)": {
              width: "calc(75% - 4.3%)",
              padding: "120px 4.3% 228px 4.3%",
            },
            "@media (max-width: 800px)": {
              width: "calc(100% - 8.6%)",
              padding: "120px 4.3% 228px 4.3%",
            },
          }}
        >
          <Box
            sx={{ fontSize: "40px", fontWeight: "400", marginBottom: "36px" }}
          >
            Privacy Policy
          </Box>
          <Stack
            direction={"column"}
            gap={"24px"}
            sx={{ div: { wordBreak: "break-word" } }}
          >
            <Box>Effective Date: July 26, 2023</Box>
            <Box>
              This Privacy Policy (“Privacy Policy”) explains how information is
              collected and used by PartnerElement Inc. (
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                “PartnerElement”
              </Box>{" "}
              or{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                “we”
              </Box>
              ,{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                “us”
              </Box>
              ,{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                “our”
              </Box>
              ).
            </Box>
            <Box>This Privacy Policy applies to the following:</Box>
            <Box component={"ol"} sx={styles.ul} type={"a"}>
              <Box component={"li"}>
                The data practices on the website at{" "}
                <Box
                  sx={{ textDecoration: "underline" }}
                  component={"a"}
                  href="https://www.partnerelement.com"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  https://www.partnerelement.com
                </Box>{" "}
                (
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Website”
                </Box>
                ).
              </Box>
              <Box component={"li"}>
                The data practices relating to the operation of the
                PartnerElement services (our{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Service”
                </Box>
                ) that we provide to clients (“Clients”). The Service offers
                Clients the ability to generate distilled presentations of
                disparate data relating to vendor relationships.
                PartnerElement’s technology includes scanning of Client-selected
                emails and the application of algorithms collecting pertinent
                data, which is curated and organized using machine learning
                models to highlight vendor conversations and procurement
                activity, providing Clients early visibility into potential
                purchases and enabling Client procurement personnel to engage
                with Client personnel and vendors to improve the Client’s
                procurement process proactively. PartnerElement collects and
                processes certain email selected by the Client, which contains
                end-user personal data in order to provide the Service to the
                Client.
              </Box>
              <Box component={"li"}>
                Our relationship with representatives of our existing and
                prospective Clients using our Service.
              </Box>
            </Box>
            <Box>
              We are committed to protecting and respecting data privacy. Please
              read this Privacy Policy carefully.
            </Box>
            <Box>
              “Personal Information” means personal information that can be
              associated with an identified or identifiable person. Personal
              information does not include information that does not identify a
              specific individual.
            </Box>
            <Box sx={styles.title}>1. Personal information we collect</Box>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>
                We collect the information you provide us when you submit an
                inquiry on our Website.
              </Box>
              <Box>
                When you visit our Website, you can submit an inquiry through
                our online contact forms. When you contact us, you will be asked
                to provide information such as your name, work email address,
                phone number, company name, and the subject of your inquiry. We
                refer to this entire data as{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Inquiry Information”
                </Box>
                .
              </Box>
            </Box>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>
                We collect and process your contact information when you engage
                us to use our Service.
              </Box>
              <Box>
                When a representative of an existing or prospective Client uses
                or wishes to use our Service, we collect that representative’s
                contact details, such as name, email, phone number, position and
                company, Service login information, and information relating to
                the engagement between us and the Client which is made with the
                involvement of the representative. We refer to this entire data
                as{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Contact Information”
                </Box>
                .
              </Box>
            </Box>
            <Box>
              You do not have a legal obligation to provide us with your Inquiry
              Information or Contact Information; however, if you choose not to
              share this information with us, we may not be able to respond to
              your inquiry or provide you the Service.
            </Box>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>
                The Service we provide involves processing information of the
                Client’s data subjects.
              </Box>
              <Box>
                In order to provide the Service to a Client, we process the
                personal information of the Client’s employees, contractors,
                and/or vendors. We do this as a data processor (also known as a
                service provider) on behalf of the Client and under the Client’s
                instructions.
              </Box>
            </Box>
            <Box>
              If you are a data subject of the Service Information (i.e., you
              are a Client’s employee, contractor, or vendor), and would like to
              obtain more information on the collection and processing of your
              personal data, please contact the Client, which is the data
              controller of your personal data.
            </Box>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>
                We also collect analytics information about your use of the
                Service and Website.
              </Box>
              <Box>
                When you visit the Website or the Service, we record and collect
                certain information about your interaction with the Website or
                the Service, including the IP address from which you access the
                Website or Service, time and date of access, type of browser
                used, language used, links clicked, and actions taken while
                using the Website or Service (
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Analytics Information“
                </Box>
                ).
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>Categories of Personal Information</Box>
              <Box>
                Depending on your use of the Website and Service, the following
                categories of personal information may have been collected in
                the preceding 12 months (as these processing activities are
                defined under applicable privacy laws):
              </Box>
            </Box>
            <Box component={"ul"} sx={styles.ul}>
              <Box component={"li"}>
                <Box component={"span"} sx={{ fontStyle: "italic" }}>
                  Identifiers
                </Box>
                , such as a name, user name, email, online identifiers, device
                identifiers, and IP address;
              </Box>
              <Box component={"li"}>
                <Box component={"span"} sx={{ fontStyle: "italic" }}>
                  Account records
                </Box>
                , such as name, business address, business email address, and
                business telephone number;
              </Box>
              <Box component={"li"}>
                <Box component={"span"} sx={{ fontStyle: "italic" }}>
                  Commercial Information
                </Box>
                , such as products or services purchased, obtained, or
                considered, and purchasing histories or tendencies;
              </Box>
              <Box component={"li"}>
                <Box component={"span"} sx={{ fontStyle: "italic" }}>
                  Internet or Electronic Network Activity
                </Box>
                , such as browsing history, search history, and your
                interactions with an Internet website, application, or service;
              </Box>
              <Box component={"li"}>
                <Box component={"span"} sx={{ fontStyle: "italic" }}>
                  Approximate (coarse) geolocation
                </Box>
                , such as location data derived from an IP address or device
                settings;
              </Box>
              <Box component={"li"}>
                <Box component={"span"} sx={{ fontStyle: "italic" }}>
                  Inferences
                </Box>
                , such as consumer preferences, drawn from the above;
              </Box>
              <Box component={"li"}>
                <Box component={"span"} sx={{ fontStyle: "italic" }}>
                  Professional/Employment information
                </Box>
                , such as company name and position.
              </Box>
            </Box>
            <Box sx={styles.title}>Data Controller and Data Processor</Box>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>
                The Client is the data controller of the Service Information,
                and PartnerElement is its data processor.
              </Box>
              <Box>
                Each Client is the data controller of its own Service
                Information. They determine the purposes and means of processing
                that data. We only process such data based on their instructions
                and for the purpose of providing the Service to them.
              </Box>
            </Box>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>
                PartnerElement is the data controller of Inquiry, Information
                Contact Information, and Analytics Information.
              </Box>
              <Box>
                PartnerElement is the data controller for the Inquiry
                Information, Contact Information, and Analytics Information, as
                explained below.
              </Box>
            </Box>
            <Box sx={styles.title}>2. How we use your personal information</Box>
            <Box>
              We use your Personal Information, both individually and combined
              together, in the following ways.
            </Box>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>
                To respond to and handle your inquiry.
              </Box>
              <Box>
                We process your Inquiry Information to contact you about your
                inquiry and handle your inquiry.
              </Box>
            </Box>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>
                To send you administrative communications.
              </Box>
              <Box>
                We process your Contact Information to send you communications
                and administrative emails, to manage the provision of the
                Service to you, and to provide you with further information and
                updates about PartnerElement’s products and technology.
              </Box>
            </Box>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>
                To send you marketing communication.
              </Box>
              <Box>
                We process your Inquiry Information and Contact Information to
                send you marketing communications such as newsletters, know-how
                articles, webinars and event invitations, and other useful
                industry information and announcements. We will do so if we
                believe you might be interested in receiving these
                communications from us. You may unsubscribe at any time via the
                unsubscribe link in each marketing communication you receive
                from us. If you provided your personal email address to us and
                we believe that you are a representative of a prospective
                Client, we will use the Inquiry Information and Contact
                Information to determine your business email address (using
                third-party tools) so that we can communicate with you through
                your business email address.
              </Box>
            </Box>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>
                To provide and maintain the Website and Service.
              </Box>
              <Box>
                We process your Analytics Information to provide, maintain and
                improve your user experience when accessing our Website or when
                using our Service. We also will use the Analytics Information
                for quality assurance and for the development and enhancement of
                the Service.
              </Box>
            </Box>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>
                We also process the Analytics Information to improve our email
                communications.
              </Box>
              <Box>
                We will use the Analytics Information to prevent fraud, resolve
                disputes, troubleshoot problems, assist with any investigations,
                enforce our terms of use for the Service, and take other actions
                otherwise permitted by law.
              </Box>
            </Box>
            <Box>
              In order to provide the Service to a Client, we process Service
              Information as a data processor (also known as a service provider)
              on behalf of the Client and under the Client’s instructions.
            </Box>
            <Box sx={styles.title}>3. How we disclose your information</Box>
            <Box>
              We may disclose your Personal Information to third parties as
              follows:
            </Box>
            <Box>
              <Box component={"span"} sx={{ fontStyle: "italic" }}>
                Service Providers.
              </Box>{" "}
              We work with other businesses (including contractors and service
              providers) who assist us with internal operations. Such service
              providers may include cloud services, email communication
              services, etc.
            </Box>
            <Box>
              <Box component={"span"} sx={{ fontStyle: "italic" }}>
                Analytics Providers.
              </Box>{" "}
              We use analytics services, such as Google Analytics, to collect
              and process certain analytics data to improve our products and
              services.
            </Box>
            <Box>
              <Box component={"span"} sx={{ fontStyle: "italic" }}>
                Affiliates and Subsidiaries.
              </Box>{" "}
              We may share your Personal Information with our companies in which
              we have an ownership interest as is necessary for the purposes
              outlined in this Privacy Policy.
            </Box>
            <Box>
              <Box component={"span"} sx={{ fontStyle: "italic" }}>
                Consent.
              </Box>{" "}
              We may share your Personal Information with your consent, for
              example, if you agree to us sharing your information with a
              certain company to hear about their products, services, or
              promotions or if you provide feedback.
            </Box>
            <Box>
              <Box component={"span"} sx={{ fontStyle: "italic" }}>
                Merger, Sale, or other asset transfer.
              </Box>{" "}
              In the event of reorganization, divestiture, merger, sale,
              bankruptcy, or dissolution, we may transfer all information we
              collect to the relevant third party.
            </Box>
            <Box>
              <Box component={"span"} sx={{ fontStyle: "italic" }}>
                As required by law and similar disclosures.
              </Box>{" "}
              We may share your Personal Information to comply with laws or to
              respond to lawful requests and legal processes and to protect the
              rights and property of our employees and customers.
            </Box>
            <Box>
              <Box component={"span"} sx={{ fontStyle: "italic" }}>
                Aggregated and De-identified Information.
              </Box>{" "}
              We may share aggregated and de-identified information or
              statistical information with others for a variety of purposes.
            </Box>
            <Box sx={styles.title}>4. How we protect your information</Box>
            <Box>
              The security of your Information is our priority, and we take a
              range of technical and organizational measures to help protect it,
              including encryption and other industry-standard methods. Even so,
              please be aware that no security measure is 100% perfect. You
              should always be diligent and use a strong password to protect
              your username and passwords to help prevent others from accessing
              your account.
            </Box>
            <Box sx={styles.title}>
              5. How long we store your personal information
            </Box>
            <Box>
              We retain the Personal Information we receive as described in this
              Privacy Policy for as long as you use our Website and/or Service
              or as necessary to fulfill the purpose(s) for which it was
              collected, provide our services with regard to the Service,
              resolve disputes, establish legal defenses, conduct audits, pursue
              legitimate business purposes, enforce our agreements, and comply
              with applicable laws.
            </Box>
            <Box sx={styles.title}>6. Your choices</Box>
            <Box>
              <Box sx={styles.title}>6.1 General</Box>
              <Box>
                Under the law of some jurisdictions, you may have certain
                choices about your Personal Information. Where you have
                consented to the processing of your Personal Information, you
                may withdraw that consent at any time and prevent further
                processing by contacting us as described below.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>
                6.2 Email and Telephone Communications
              </Box>
              <Box>
                You may choose to opt out of receiving certain email
                communications by following the instructions in the email to
                unsubscribe. However, we will continue to send
                transaction-related emails regarding products or services you
                have requested.
              </Box>
            </Box>
            <Box>
              You may choose to opt out of receiving certain SMS message
              communications by following the instructions in the text message
              to unsubscribe.
            </Box>
            <Box>
              We process requests to be placed on do-not-mail, do-not-phone, and
              do-not-contact lists as required by applicable law.
            </Box>
            <Box>
              <Box sx={styles.title}>6.3 Mobile Devices</Box>
              <Box>
                We may send you push notifications through our mobile
                application. You may at any time opt out from receiving these
                types of communications by changing the settings on your mobile
                device. We may also collect location-based information if you
                use our mobile applications. You may opt out of this collection
                by changing the settings on your mobile device.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>6.4 “Do Not Track”</Box>
              <Box>
                Do Not Track (“DNT”) is a privacy preference that users can set
                in certain web browsers. Please note that we do not respond to
                or honor DNT signals or similar mechanisms transmitted by web
                browsers.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>
                6.5 Cookies and Interest-Based Advertising
              </Box>
              <Box>
                You may stop or restrict the placement of third-party tracking
                technologies on your device or remove them by adjusting your
                preferences as your browser or device permits. You can access
                these and learn more about targeted advertising and consumer
                choice and privacy at{" "}
                <Box
                  sx={{ textDecoration: "underline" }}
                  component={"a"}
                  href="www.networkadvertising.org/managing/opt_out.asp"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.networkadvertising.org/managing/opt_out.asp
                </Box>
                ,{" "}
                <Box
                  sx={{ textDecoration: "underline" }}
                  component={"a"}
                  href="http://www.youronlinechoices.eu/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  http://www.youronlinechoices.eu/
                </Box>
                ,{" "}
                <Box
                  sx={{ textDecoration: "underline" }}
                  component={"a"}
                  href="https://youradchoices.ca/choices/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  https://youradchoices.ca/choices/
                </Box>
                , and{" "}
                <Box
                  sx={{ textDecoration: "underline" }}
                  component={"a"}
                  href="www.aboutads.info/choices/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  www.aboutads.info/choices/
                </Box>
                . To separately make choices for mobile apps on a mobile device,
                you can download DAA's AppChoices application from your device's
                app store.
              </Box>
            </Box>
            <Box sx={styles.title}>7. International data transfers</Box>
            <Box>
              The main location where we process your Personal Information is
              the United States; however, we may also transfer your Personal
              Information to other countries, and you agree that such Personal
              Information may be transferred, processed, and stored anywhere in
              the world, and you authorize us to transfer your Personal
              Information across the national border and to other countries. The
              privacy protections and rights of authorities to access your
              Personal Information in these countries may not be equivalent to
              those in your country. We have taken appropriate safeguards to
              require that your Personal Information will remain protected and
              require our third-party service providers and partners to have
              appropriate safeguards as well.
            </Box>
            <Box sx={styles.title}>8. Your privacy rights</Box>
            <Box>
              Under the law of some jurisdictions, you may seek confirmation
              regarding whether we process Personal Information about you,
              request access to Personal Information, and ask that we correct,
              amend or delete that information where it is inaccurate or has
              been processed in violation of the applicable law.
            </Box>
            <Box>
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                EEA (European Economic Area) rights
              </Box>
              <Box>
                If you are habitually located in the European Union,
                Switzerland, or the United Kingdom, you have the right to
                access, rectify, download, or erase your information, as well as
                the right to restrict and object to certain processing of your
                information. Before we process any request, we will take
                commercially reasonable steps to verify your identity.
              </Box>
            </Box>
            <Box sx={styles.title}>9. Compliance and accountability</Box>
            <Box>
              If, after reviewing this Privacy Policy, you would like to contact
              us about our privacy practices or exercise any of your privacy
              rights, you may use any of the methods set out in Section 11 of
              this Privacy Policy.
            </Box>
            <Box>
              This Privacy Policy shall be implemented by us and all our
              operating divisions, subsidiaries, and affiliates. We have put in
              place mechanisms to verify ongoing compliance with this Privacy
              Policy.
            </Box>
            <Box sx={styles.title}>10. Children’s information</Box>
            <Box>
              Our Service and Website are not directed to children under 16 (or
              other ages as required by local law), and we do not knowingly
              collect personal information from children. If you learn that your
              child has provided us with Personal Information without your
              consent, you may contact us as set forth below. If we learn that
              we have collected any Personal Information in violation of
              applicable law, we will promptly take steps to delete such
              information and terminate the child’s account.
            </Box>
            <Box sx={styles.title}>11. How to contact us</Box>
            <Box>
              For questions about our Privacy Policy, to make choices about
              receiving marketing or promotional communications, or if you have
              a request to access, correct, or delete your personal data, you
              may contact us at{" "}
              <Box
                sx={{ textDecoration: "underline" }}
                component={"a"}
                href="mailto:privacy@partnerelement.com"
                target={"_blank"}
              >
                privacy@partnerelement.com
              </Box>
              .
            </Box>
            <Box>
              If you are habitually located in the EEA, you also have a right to
              contact your local Data Protection Authority.
            </Box>
            <Box sx={styles.title}>12. Changes to our privacy policy</Box>
            <Box>
              We may change this Privacy Policy from time to time. We will post
              any Privacy Policy changes on this page, and if the changes are
              significant, we will provide a more prominent notice by adding a
              notification on the Service home pages and login screens or by
              sending you an email notification. We encourage you to review our
              Privacy Policy whenever you use the Service to stay informed about
              our information practices and the ways how you can help protect
              your privacy.
            </Box>
            <Box>
              If you disagree with any changes made to this Privacy Policy, you
              will need to stop using the Services and deactivate your
              account(s), as outlined above.
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
