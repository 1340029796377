import React from 'react';
import { Box, useTheme } from '@mui/material';

const StyledHeader = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        color: theme.palette.text.neutral,
        fontFamily: "Coil",
        fontSize: "80px",
        fontWeight: "400",
        lineHeight: "80px",
        letterSpacing: "-2.4px",
        marginBottom: "64px",
        "@media (max-width: 1024px)": {
          fontSize: "64px",
          lineHeight: "64px",
          letterSpacing: "-1.8px",
          marginBottom: "50px",
        },
        "@media (max-width: 800px)": {
          fontSize: "50px",
          lineHeight: "50px",
          letterSpacing: "-1.8px",
          marginBottom: "50px",
        },
        "@media (max-width: 600px)": {
          fontSize: "40px",
          lineHeight: "40px",
          letterSpacing: "-1.2px",
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default StyledHeader;
