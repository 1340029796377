import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Box, Stack } from "@mui/material";

import RequestDemo from "../components/popups/requestDemo";
import StyledButton from "../components/styled/styledButton";

import { isMobileDevice } from "../utils/deviceUtils";
import StyledStack from "../components/styled/styledStack";
import ContentBox from "../components/styled/contentBox";
import Separator from "../components/styled/separator";

import { useLocation } from "react-router-dom";

export default function Product() {
  const [isOpenRequestDemo, setIsOpenRequestDemo] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const scrollToElement = () => {
      const hash = location.hash;
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          window.scrollTo({
            top: element.offsetTop - 100,
            behavior: "smooth",
          });
        }
      }
    };

    scrollToElement();
  }, [location]);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsMobile(isMobileDevice(userAgent));
  }, []);

  return (
    <>
      <Helmet>
        <title>Partner Element - Product</title>
        <link rel="icon" href="/favicon.ico" sizes="any" />
      </Helmet>

      <StyledStack id="ai" alignItems={"center"} marginTop={"60px"}>
        <Stack
          sx={{
            width: "50%",
            "@media (max-width: 800px)": {
              width: "50%",
            },
            "@media (max-width: 600px)": {
              width: "fit-content",
            },
          }}
        >
          <ContentBox isMobile={isMobile}>
            Understand your active supplier deals in one place
          </ContentBox>
        </Stack>
        <Box
          sx={{
            background: `url("/product-1.png") no-repeat center center`,
            backgroundSize: "contain",
            height: "429px",
            width: "50%",
            "@media (max-width: 800px)": {
              height: "350px",
              width: "50%",
            },
            "@media (max-width: 600px)": {
              height: "300px",
              width: "100%",
            },
          }}
        >
          &nbsp;
        </Box>
      </StyledStack>

      <StyledStack
        justifyContent={"center"}
        sx={{
          "@media (max-width: 600px)": {
            paddingTop: "10px",
          },
        }}
      >
        <Separator />
      </StyledStack>

      <StyledStack
        id="dashboards"
        alignItems={"center"}
        flexDirection={"row-reverse"}
        sx={{
          "@media (max-width: 600px)": {
            paddingTop: "30px",
          },
        }}
      >
        <Stack
          sx={{
            width: "50%",
            "@media (max-width: 800px)": {
              width: "50%",
            },
            "@media (max-width: 600px)": {
              width: "fit-content",
            },
            "@media (min-width: 1024px)": {
              alignItems: "flex-end",
            }
          }}
        >
          <ContentBox isMobile={isMobile}>
            Proactively connect with business partners to collaborate on
            high-value deals
          </ContentBox>
        </Stack>
        <Box
          sx={{
            background: `url("/product-2.png") no-repeat center center`,
            backgroundSize: "contain",
            height: "472px",
            width: "50%",
            "@media (max-width: 800px)": {
              height: "350px",
              width: "50%",
            },
            "@media (max-width: 600px)": {
              height: "300px",
              width: "100%",
            },
          }}
        >
          &nbsp;
        </Box>
      </StyledStack>

      <StyledStack
        justifyContent={"center"}
        sx={{
          "@media (max-width: 600px)": {
            paddingTop: "10px",
          },
        }}
      >
        <Separator />
      </StyledStack>

      <StyledStack
        id="visibility"
        alignItems={"center"}
        sx={{
          "@media (max-width: 600px)": {
            paddingTop: "30px",
          },
        }}
      >
        <Stack
          sx={{
            width: "50%",
            "@media (max-width: 800px)": {
              width: "50%",
            },
            "@media (max-width: 600px)": {
              width: "fit-content",
            },
          }}
        >
          <ContentBox isMobile={isMobile}>
            Automatically detect prospective deals across the company
          </ContentBox>
        </Stack>
        <Box
          sx={{
            background: `url("/product-3.png") no-repeat center center`,
            backgroundSize: "contain",
            height: "455px",
            width: "50%",
            "@media (max-width: 800px)": {
              height: "350px",
              width: "50%",
            },
            "@media (max-width: 600px)": {
              height: "300px",
              width: "100%",
            },
          }}
        >
          &nbsp;
        </Box>
      </StyledStack>

      <StyledStack
        justifyContent={"center"}
        sx={{
          "@media (max-width: 600px)": {
            paddingTop: "10px",
          },
        }}
      >
        <Separator />
      </StyledStack>

      <StyledStack
        id="profiles"
        alignItems={"center"}
        flexDirection={"row-reverse"}
        sx={{
          "@media (max-width: 600px)": {
            paddingTop: "30px",
          },
        }}
      >
        <Stack
          sx={{
            width: "50%",
            "@media (max-width: 800px)": {
              width: "50%",
            },
            "@media (max-width: 600px)": {
              width: "fit-content",
            },
            "@media (min-width: 1024px)": {
              alignItems: "flex-end",
            }
          }}
        >
          <ContentBox isMobile={isMobile}>
            Increase efficiency with intelligent supplier profiles
          </ContentBox>
        </Stack>
        <Box
          sx={{
            background: `url("/product-4.png") no-repeat center center`,
            backgroundSize: "contain",
            height: "429px",
            width: "50%",
            "@media (max-width: 800px)": {
              height: "350px",
              width: "50%",
            },
            "@media (max-width: 600px)": {
              height: "300px",
              width: "100%",
            },
          }}
        >
          &nbsp;
        </Box>
      </StyledStack>

      <StyledStack
        justifyContent={"center"}
        sx={{
          "@media (max-width: 600px)": {
            paddingTop: "10px",
          },
        }}
      >
        <Separator />
      </StyledStack>

      <StyledStack
        alignItems={"flex-end"}
        marginTop={"-70px"}
        sx={{
          "@media (max-width: 800px)": {
            paddingTop: "80px",
          },
          "@media (max-width: 600px)": {
            paddingTop: "90px",
          },
          "@media (min-width: 1024px)": {
            alignItems: "center",
          }
        }}
      >
        <Stack
          sx={{
            width: "40%",
            "@media (max-width: 800px)": {
              width: "40%",
            },
            "@media (max-width: 600px)": {
              width: "100%",
            }
          }}
        >
          <ContentBox isMobile={isMobile} sx={{
            "@media (min-width: 1024px)": {
              paddingTop: "40px",
            }
          }}>
            Ready to maximize speed, compliance, and value for your company?
          </ContentBox>

          {!isMobile && (
            <Box
              paddingLeft={"50px"}
              sx={{
                "@media (min-width: 1024px)": {
                  paddingLeft: "0",
                },
                "@media (max-width: 800px)": {
                  paddingLeft: "20px",
                },
              }}
            >
              <StyledButton
                onClick={() => setIsOpenRequestDemo(true)}
                sx={{
                  padding: "16px 30px",
                  borderRadius: "5px",
                  fontWeight: "400",
                  fontSize: "18px",
                  height: "48px",
                  marginBottom: "60px",
                  width: isMobile ? "100%" : "fit-content",
                }}
                variant={"contained"}
                color={"buttonPrimary"}
                label={"Request demo"}
              />
            </Box>
          )}
        </Stack>
        <Box
          width={isMobile ? "100%" : "60%"}
          sx={{
            background: `url("/product-5.png") no-repeat center bottom`,
            backgroundSize: "auto 100%",
            height: "472px",
            width: "60%",
            "@media (max-width: 1024px)": {
              height: "450px",
              width: "60%",
              backgroundSize: "auto 90%",
            },
            "@media (max-width: 800px)": {
              height: "300px",
              width: "60%",
              backgroundSize: "auto 90%",
            },
            "@media (max-width: 600px)": {
              width: "100%",
              height: "230px",
              backgroundSize: "contain",
            },
          }}
        >
          &nbsp;
        </Box>
        {isMobile && (
          <Box width={"100%"}>
            <StyledButton
              onClick={() => setIsOpenRequestDemo(true)}
              sx={{
                padding: "16px 30px",
                borderRadius: "5px",
                fontWeight: "400",
                fontSize: "18px",
                height: "48px",
                marginBottom: "60px",
                marginTop: "-10px",
                width: isMobile ? "100%" : "fit-content",
              }}
              variant={"contained"}
              color={"buttonPrimary"}
              label={"Request demo"}
            />
          </Box>
        )}
      </StyledStack>

      <RequestDemo
        isOpen={isOpenRequestDemo}
        close={() => setIsOpenRequestDemo(false)}
      />
    </>
  );
}
