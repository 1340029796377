import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Menu, MenuItem, Stack, useTheme } from "@mui/material";
import RequestDemo from "../components/popups/requestDemo";
import StyledButton from "./styled/styledButton";
import { isMobileDevice } from "../utils/deviceUtils";
import MenuDownIcon from "../menu-down.svg";
import MenuIcon from "@mui/icons-material/Menu";
import MobileMenu from "./mobile-menu";
import { IconButton } from '@mui/material';

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const pages = ["For Procurement", "For the CIO"];
  const urls = ["/procurement", "/cio"];
  const productFeatures = [
    "AI-powered supplier deal & stage detection",
    "Comprehensive dashboards",
    "Early visibility",
    "Intelligent supplier profiles",
  ];
  const featuresUrls = [
    "/product#ai",
    "/product#dashboards",
    "/product#visibility",
    "/product#profiles",
  ];

  const [isOpenRequestDemo, setIsOpenRequestDemo] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [anchorElProduct, setAnchorElProduct] = useState(null);
  const [productMenuOpen, setProductMenuOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isProductMenuOpen = Boolean(anchorElProduct);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsMobile(isMobileDevice(userAgent));
  }, []);

  const onClickLogin = () => {
    const loginButton = document.createElement("a");
    loginButton.href = "https://app.partnerelement.com/login";
    loginButton.click();
  };

  const handleProductMenuClick = (event) => {
    setAnchorElProduct(event.currentTarget);
  };

  const toggleProductMenu = (event) => {
    setProductMenuOpen((prev) => !prev);
    handleProductMenuClick(event);
  };

  const handleProductMenuClose = () => {
    setAnchorElProduct(null);
    setProductMenuOpen(false);
  };

  const renderProductMenuIcon = () => {
    return (
      <img
        src={MenuDownIcon}
        alt="Menu icon"
        style={{
          transform: isProductMenuOpen ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.3s",
        }}
      />
    );
  };

  const isActive = (path) => {
    if (!path.startsWith("#") && location.pathname === path) {
      return true;
    }

    return location.hash === path;
  };

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          position: "fixed",
          width: "calc(100vw - 21.6%)",
          height: "56px",
          zIndex: "2",
          top: "0",
          left: "0",
          background: theme.palette.primary.dark,
          paddingX: "10.8%",
          "@media (max-width: 1240px)": {
            width: "calc(100vw - 8.6%)",
            padding: "6px 4.3%",
          },
        }}
      >
        <Box
          sx={{ marginY: "6px", cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          <img src={"logo.svg"} alt="logo" width={175} height={25} />
        </Box>

        {!isMobile && (
          <Stack direction="row" spacing={2}>
            {pages.map((page, idx) => (
              <StyledButton
                key={page}
                onClick={() => navigate(`${urls[idx]}`)}
                variant={"text"}
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  "&:focus": {
                    background: "none !important",
                  },
                }}
                color={
                  isActive(urls[idx]) ? "buttonTextActive" : "buttonTextPrimary"
                }
                label={page}
              />
            ))}
            <Button
              endIcon={renderProductMenuIcon()}
              sx={{
                color: isActive("/product") ? "#fff" : "buttonTextPrimary",
                fontWeight: "400",
                fontSize: "16px",
                backgroundColor: productMenuOpen
                  ? "theme.palette.primary.light"
                  : "theme.palette.primary.main",
                "&:focus": {
                  background: "none !important",
                },
              }}
              onClick={toggleProductMenu}
            >
              Product
            </Button>
            <Menu
              anchorEl={anchorElProduct}
              open={Boolean(anchorElProduct)}
              onClose={handleProductMenuClose}
            >
              {productFeatures.map((feature, idx) => (
                <MenuItem
                  key={feature}
                  onClick={() => {
                    handleProductMenuClose();
                    navigate(`${featuresUrls[idx]}`);
                  }}
                  sx={{
                    fontFamily: "CoFo Sans",
                  }}
                >
                  {feature}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        )}

        <Stack direction={"row"} gap={"16px"} alignItems={"center"}>
          <StyledButton
            onClick={onClickLogin}
            variant={"text"}
            color={"buttonTextPrimary"}
            label={"Log in"}
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              height: "24px",
            }}
          />

          <IconButton
            edge="start"
            aria-label="menu"
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            sx={{
              display: { xs: 'flex', sm: 'none' },
              color: '#A7A4E0',
            }}
          >
            <MenuIcon />
          </IconButton>

          <MobileMenu
            isOpen={isDrawerOpen}
            toggleDrawer={setIsDrawerOpen}
            pages={pages}
            urls={urls}
            productFeatures={productFeatures}
            featuresUrls={featuresUrls}
          />
          {!isMobile && (
            <StyledButton
              sx={{
                padding: "12px 30px",
                fontFamily: "CoFo Sans",
                borderRadius: "5px",
                fontWeight: "400",
                width: "165px",
                whiteSpace: "nowrap",
                fontSize: "16px",
                lineHeight: "20px",
                height: "32px",
                "@media (max-width: 800px)": {
                  display: "none",
                },
              }}
              color={"buttonGrey"}
              variant={"outlined"}
              label={"Request demo"}
              onClick={() => setIsOpenRequestDemo(true)}
            />
          )}
        </Stack>
      </Stack>
      <RequestDemo
        isOpen={isOpenRequestDemo}
        close={() => setIsOpenRequestDemo(false)}
      />
    </>
  );
};

export default Header;
