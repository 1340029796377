import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { Box, FormHelperText, TextField } from "@mui/material";

const StyledInput = ({
  label,
  isShowLabel,
  placeholder,
  value,
  onChange,
  variant,
  inputStyles,
  inputProps,
  isError,
  error,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const styles = () => {
    switch (variant) {
      case "outlined":
        return {
          textField: {
            color: "#382F2D !important",
            borderStyle: "solid",
            borderColor: !isError
              ? isFocus
                ? "#6558B1 !important"
                : "#8C8279"
              : "#E03C31 !important",
            borderWidth: !isError
              ? isFocus
                ? "2px !important"
                : "1px"
              : "2px !important",
            borderRadius: "5px",
            ":hover": {
              borderStyle: "solid",
              borderColor: "#8C8279",
              borderWidth: "2px",
              borderRadius: "5px",
            },
          },
        };
      default:
        return {
          textField: {},
        };
    }
  };

  return (
    <Box width={"100%"} sx={{ position: "relative" }}>
      <AnimatePresence>
        {isShowLabel && (
          <motion.div
            initial={{ y: 16, opacity: 0 }}
            animate={{ y: -22, opacity: 1 }}
            exit={{ y: 16, opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              position: "absolute",
              left: "16px",
            }}
          >
            <Box
              sx={{
                color: isError ? "#E03C31" : "#514689",
                fontSize: "12px",
              }}
              component={"span"}
            >
              {label}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
      <TextField
        {...props}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        sx={{ width: "100%", fieldset: { border: "none" } }}
        InputProps={{
          ...inputProps,
          sx: { ...inputStyles, ...styles().textField },
        }}
        variant={variant}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {isError && (
        <FormHelperText
          sx={{
            fontSize: "12px",
            fontFamily: "CoFo Sans",
            marginLeft: "16px",
            marginTop: "8px",
            color: "#E03C31",
          }}
        >
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

export default StyledInput;
