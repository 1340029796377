import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

const StyledStack = styled(Stack)(
  ({ theme, flexDirection = "row", ...props }) => ({
    flexDirection: flexDirection,
    justifyContent: "space-between",
    gap: "20px",
    padding: "73px 10.8% 0 10.8%",
    ...(props.sx || {}),
    [theme.breakpoints.down("lg")]: {
      padding: "73px 4.3% 0 4.3%",
    },
    [theme.breakpoints.down("md")]: {
      padding: "23px 4.3% 0 4.3%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "73px 4.3% 0 4.3%",
      flexWrap: "wrap",
      gap: "10px",
    },
  })
);

export default StyledStack;
