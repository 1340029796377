import React from "react";

import { Button, useTheme } from "@mui/material";

const StyledButton = ({ label, sx, color, variant, ...props }) => {
  const theme = useTheme();
  const styles = () => {
    switch (variant) {
      case "contained":
        return {
          color: theme.palette[color].text,
          background: theme.palette[color].background,
          borderColor: theme.palette[color].background,
          borderWidth: "2px",
          borderStyle: "solid",
          boxShadow: "none",

          ":hover": {
            color: theme.palette[color].text,
            background: theme.palette[color].background,
            borderColor: theme.palette[color].hoverBorder,
            boxShadow: "none",
          },
          ":focus": {
            color: theme.palette[color].text,
            background: theme.palette[color].background,
            borderColor: theme.palette[color].focusBorder,
            boxShadow: "none",
          },
        };
      case "outlined":
        return {
          color: theme.palette[color].background,
          borderColor: theme.palette[color].background,
          borderWidth: "1px",
          borderStyle: "solid",
          boxShadow: "none",

          ":hover": {
            color: theme.palette[color].background,
            borderColor: theme.palette[color].background,
            borderWidth: "2px",

            boxShadow: "none",
          },
          ":focus": {
            color: theme.palette[color].background,
            borderColor: theme.palette[color].background,
            borderWidth: "2px",
            boxShadow: "none",
          },
        };
      case "text":
        return {
          color: `${theme.palette[color].color} !important`,
          boxShadow: "none",

          ":hover": {
            background: theme.palette[color].background,
            boxShadow: "none",
          },
          ":focus": {
            background: theme.palette[color].background,
            boxShadow: "none",
          },
        };

      default:
        return {};
    }
  };

  return (
    <Button
      {...props}
      variant={variant}
      sx={{
        ...sx,
        ...styles(),
        whiteSpase: "nowrap",
        span: { display: "none" },
      }}
    >
      {label}
    </Button>
  );
};

export default StyledButton;
