import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Box, Stack, Typography, useTheme } from "@mui/material";

import RequestDemo from "../components/popups/requestDemo";
import StyledButton from "../components/styled/styledButton";

import { isMobileDevice } from "../utils/deviceUtils";
import StyledHeader from "../components/styled/styledHeader";
import StyledBox from "../components/styled/styledBox";

export default function CIO() {
  const theme = useTheme();

  const iconTexts = [
    "Reduce risk through just-in-time employee engagement and education",
    "Speed up buying with supplier insights",
    "Avoid redundant software in your tech stack",
  ];

  const iconImages = [
    "/procurement-icon-3.svg",
    "/procurement-icon-2.svg",
    "/procurement-icon-1.svg",
  ];

  const [isOpenRequestDemo, setIsOpenRequestDemo] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsMobile(isMobileDevice(userAgent));
  }, []);

  return (
    <>
      <Helmet>
        <title>Partner Element - For the CIO</title>
        <link rel="icon" href="/favicon.ico" sizes="any" />
      </Helmet>

      <Box>
        <StyledBox>
          <Box
            sx={{
              color: theme.palette.text.neutral,
              height: "590px",
              fontFamily: "Coil",
              fontSize: "80px",
              fontWeight: "400",
              lineHeight: "80px",
              letterSpacing: "-2.4px",
              marginBottom: "64px",
              marginTop: "80px",
              paddingRight: "20%",
              background: `url("/cio-arrow.png") no-repeat right bottom`,
              "@media (max-width: 1200px)": {
                fontSize: "70px",
                lineHeight: "70px",
                letterSpacing: "-1.8px",
              },
              "@media (max-width: 1024px)": {
                fontSize: "64px",
                lineHeight: "64px",
                height: "500px",
                letterSpacing: "-1.8px",
                backgroundSize: "contain",
                backgroundPosition: "center bottom",
              },
              "@media (max-width: 800px)": {
                fontSize: "60px",
                lineHeight: "60px",
                height: "500px",
                letterSpacing: "-1.8px",
                backgroundSize: "contain",
                backgroundPosition: "center bottom",
              },
              "@media (max-width: 600px)": {
                marginTop: "40px",
                fontSize: "40px",
                lineHeight: "50px",
                letterSpacing: "-1.2px",
                paddingRight: "0",
                background: `url("/cio-arrow.png") no-repeat right`,
                backgroundPositionY: "85%",
                backgroundSize: "contain",
              },
              "@media (max-width: 400px)": {
                marginTop: "20px",
                fontSize: "40px",
                lineHeight: "50px",
                letterSpacing: "-1.2px",
                paddingRight: "1%",
                background: `url("/cio-arrow.png") no-repeat right`,
                backgroundPositionY: "180px",
                backgroundSize: "120%",
              },
            }}
          >
            {isMobile ? (
              <>
                <Box>Visibility a mile ahead</Box>
              </>
            ) : (
              <>
                <Box>Visibility a mile ahead</Box>
              </>
            )}
            <Box
              sx={{
                width: "100%",
                fontSize: isMobile ? "32px " : "40px",
                fontWeight: " 400",
                lineHeight: "50px",
                letterSpacing: "-0.4px",
                marginBottom: "80px",
                fontFamily: "CoFo Sans",
                "@media (min-width: 1024px)": {
                  paddingTop: "10px",
                },
                "@media (max-width: 1024px)": {
                  width: "638px",
                  fontSize: "36px",
                  lineHeight: "40px",
                },
                "@media (max-width: 800px)": {
                  width: "536px",
                  fontSize: isMobile ? "32px " : "36px",
                  lineHeight: "40px",
                },
                "@media (max-width: 600px)": {
                  width: "80%",
                  fontSize: "24px",
                  lineHeight: "30px",
                  marginTop: "16px",
                  marginBottom: "160px",
                  paddingRight: "20%",
                },
                "@media (max-width: 400px)": {
                  width: "80%",
                  fontSize: "24px",
                  lineHeight: "30px",
                  marginTop: "16px",
                  marginBottom: "120px",
                  paddingRight: "20%",
                },
              }}
            >
              {isMobile ? (
                <>
                  <Box>Identify technology</Box>
                  <Box>supplier deals early—</Box>
                  <Box>turn Shadow IT into a competitive</Box>
                  <Box>advantage!</Box>
                </>
              ) : (
                <>
                  <Box>Identify technology supplier deals early—</Box>
                  <Box>turn Shadow IT into a competitive</Box>
                  <Box>advantage!</Box>
                </>
              )}
            </Box>

            <StyledButton
              onClick={() => setIsOpenRequestDemo(true)}
              sx={{
                padding: "16px 30px",
                borderRadius: "5px",
                fontWeight: "400",
                fontSize: "18px",
                height: "48px",
                marginBottom: "0",
                width: isMobile ? "100%" : "fit-content",
                "@media (max-width: 600px)": {
                  marginTop: "90px",
                },
                "@media (max-width: 400px)": {
                  marginTop: "90px",
                },
              }}
              variant={"contained"}
              color={"buttonPrimary"}
              label={"Request demo"}
            />
          </Box>
        </StyledBox>
      </Box>

      <Box
        width={"100%"}
        backgroundColor={"#DADAEF"}
        height={isMobile ? "auto" : "550px"}
      >
        <StyledBox>
          <Stack flexDirection={"column"}>
            <Box>
              <StyledHeader>Drive results with early visibility</StyledHeader>
            </Box>

            <Stack
              flexDirection={isMobile ? "column" : "row"}
              gap={isMobile ? "20px" : "0"}
              paddingBottom={"20px"}
            >
              {iconTexts.map((text, index) => (
                <Stack
                  padding={"4px"}
                  key={index}
                  width={isMobile ? "90%" : "33%"}
                  flexDirection={isMobile ? "row" : "column"}
                  gap={isMobile ? "20px" : "0"}
                >
                  <Box marginBottom={"16px"}>
                    <img src={iconImages[index]} alt={text} />
                  </Box>
                  <Box width={"80%"}>
                    <Typography
                      sx={{
                        fontSize: "32px",
                        lineHeight: "40px",
                        fontFamily: "CoFo Sans",
                        "@media (max-width: 1024px)": {
                          fontSize: "28px",
                          lineHeight: "34px",
                        },
                        "@media (max-width: 800px)": {
                          fontSize: "28px",
                          lineHeight: "34px",
                        },
                        "@media (max-width: 600px)": {
                          fontSize: "24px",
                          lineHeight: "30px",
                        },
                      }}
                    >
                      {text}
                    </Typography>
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </StyledBox>
      </Box>

      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent={"space-between"}
        marginBottom={"80px"}
        gap={"40px"}
        sx={{
          "@media (max-width: 600px)": {
            flexWrap: "wrap",
          },
          padding: "73px 10.8% 0 10.8%",
          "@media (max-width: 1240px)": {
            padding: "73px 4.3% 0 4.3%",
          },
        }}
      >
        <Stack
          width={isMobile ? "100%" : "50%"}
          sx={{
            "@media (min-width: 1024px)": {
              justifyContent: "center",
            },
          }}
        >
          <Box
            sx={{
              maxWidth: "450px",
              padding: "50px",
              paddingLeft: "0",
              fontSize: isMobile ? "32px " : "40px",
              fontWeight: "400",
              lineHeight: "50px",
              letterSpacing: "-0.4px",
              "@media (max-width: 800px)": {
                width: "100%",
                padding: "20px",
                fontSize: isMobile ? "32px " : "36px",
                lineHeight: "40px",
              },
              "@media (max-width: 600px)": {
                width: "100%",
                fontSize: "32px",
                lineHeight: "40px",
                padding: "5px",
              },
            }}
          >
            Automatically detect prospective deals across the company
          </Box>

          {!isMobile && (
            <Box>
              <StyledButton
                onClick={() => setIsOpenRequestDemo(true)}
                sx={{
                  padding: "16px 30px",
                  borderRadius: "5px",
                  fontWeight: "400",
                  fontSize: "18px",
                  height: "48px",
                  width: isMobile ? "100%" : "fit-content",
                }}
                variant={"contained"}
                color={"buttonPrimary"}
                label={"Request demo"}
              />
            </Box>
          )}
        </Stack>
        <Box
          width={isMobile ? "100%" : "50%"}
          sx={{
            background: `url("/ui-sample.png") no-repeat center center`,
            backgroundSize: "contain",
            height: "472px",
            "@media (max-width: 800px)": {
              height: "300px",
            },
            "@media (max-width: 600px)": {
              height: "300px",
            },
          }}
        >
          &nbsp;
        </Box>
        {isMobile && (
          <Box>
            <StyledButton
              onClick={() => setIsOpenRequestDemo(true)}
              sx={{
                padding: "16px 16px",
                borderRadius: "5px",
                fontWeight: "400",
                fontSize: "18px",
                height: "48px",
                marginBottom: "20px",
                width: isMobile ? "100%" : "fit-content",
              }}
              variant={"contained"}
              color={"buttonPrimary"}
              label={"Request demo"}
            />
          </Box>
        )}
      </Stack>

      <RequestDemo
        isOpen={isOpenRequestDemo}
        close={() => setIsOpenRequestDemo(false)}
      />
    </>
  );
}
