import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const ContentBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  maxWidth: "450px",
  padding: "50px",
  fontSize: isMobile ? "32px" : "40px",
  fontWeight: "400",
  lineHeight: "50px",
  letterSpacing: "-0.4px",
  [theme.breakpoints.down("md")]: {
    width: "536px",
    fontSize: isMobile ? "32px" : "36px",
    lineHeight: "40px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    fontSize: "32px",
    lineHeight: "40px",
    padding: "0",
  },
  "@media (min-width: 1024px)": {
    paddingLeft: "0",
    paddingRight: "0",
  },
  "@media (max-width: 800px)": {
    width: "80%",
    padding: "20px",
    fontSize: "28px",
    lineHeight: "34px",
  },
  "@media (max-width: 600px)": {
    width: "fit-content",
  },
}));

export default ContentBox;
