import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "0 !important",
          fontFamily: "CoFo Sans",
          "::placeholder": { fontFamily: "CoFo Sans" },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { textTransform: "none", fontFamily: "CoFo Sans" },
      },
    },
  },
  palette: {
    input: {
      text: "#382F2D",
      placeholder: "#665A56",
      border: "#8C8279",
      label: "#514689",
      hoverBorder: "#8C8279",
      focusBorder: "#6558B1",
      errorBorder: "#E03C31",
      disabledBorder: "#CBC4BC",
      disabledText: "#ACA39A",
    },

    buttonPrimary: {
      background: "#6558B1",
      text: "#FAFAFA",
      hoverBorder: "#514689",
      focusBorder: "#514689",
      light: "rgba(225, 221, 216, 0.05)",
    },
    buttonGrey: {
      background: "#A7A4E0",
      text: "#2F1E67",
      hoverBorder: "#DADAEF",
      focusBorder: "#DADAEF",
      light: "rgba(225, 221, 216, 0.05)",
    },
    buttonText: {
      color: "rgba(167, 164, 224, 1)",
      background: "rgba(56, 47, 45, 0.05)",
    },
    buttonTextFooter: {
      color: "rgba(167, 164, 224, 1)",
      background: "rgba(225, 221, 216, 0.05)",
    },
    buttonTextPrimary: {
      color: "#A7A4E0",
      background: "rgba(225, 221, 216, 0.05)",
    },
    buttonTextActive: {
      color: "#fff",
      background: "rgba(225, 221, 216, 0.05)",
    },
    primary: {
      main: "#A7A4E0",
      dark: "#2F1E67",
      light: "#A7A4E0",
      medium: "#6558B1",
    },
    text: {
      offWhite: "#FAFAFA",
      neutral: "#382F2D",
      primary: "#6558B1",
      medium: "#8C8279",
    },
    background: {
      main: "#F5F4F2",
    },
    footer: {
      backgroundColor: "rgba(56, 47, 45, 1)",
      fontColor: "rgba(245, 244, 242, 1)",
    },
  },
});
