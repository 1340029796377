import React, { useEffect, useState } from "react";

import { Box, IconButton, Stack, useTheme } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

import StyledButton from "../styled/styledButton";
import StyledInput from "../styled/styledInput";

const RequestDemoForm = ({ close, isOpen, setView }) => {
  const theme = useTheme();

  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
  });
  const sendForm = async () => {
    const response = await fetch(
      "https://app.partnerelement.com/api/marketing/request_demo",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        credentials: "include",
        body: JSON.stringify(formData),
      },
    );
    if (response.ok) {
      setView("success");
    } else {
      setView("error");
    }
  };
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    if (isOpen) {
      setIsValidEmail(true);
      setFormData({
        name: "",
        company: "",
        email: "",
      });
    }
  }, [isOpen]);

  return (
    <Box
      sx={{
        fontFamily: "CoFo Sans",
        overflow: "auto",
        height: "calc(100% - 24px)",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"end"}
        sx={{ paddingTop: "28px", paddingRight: "28px" }}
      >
        <IconButton
          sx={{
            padding: "4px",
            borderRadius: "5px",
            background: "transparent",
            color: "#665A56",
            ":hover": { background: "rgba(56, 47, 45, 0.05)" },
            ":focus": { background: "rgba(56, 47, 45, 0.05)" },
            span: { display: "none" },
          }}
          onClick={close}
        >
          <CloseRounded sx={{ color: "#665A56", fontSize: "16px" }} />
        </IconButton>
      </Stack>
      <form>
        <Box sx={{ padding: "0 40px 40px 40px" }}>
          <Box
            sx={{
              marginBottom: "30px",
              fontSize: "18px",
              color: theme.palette.text.neutral,
            }}
          >
            Please fill out to see demo
          </Box>
          <Stack direction={"column"} gap={"24px"}>
            <StyledInput
              label={"Name"}
              name={"name"}
              onChange={(event) =>
                setFormData({ ...formData, name: event.target.value })
              }
              placeholder={"Name"}
              value={formData.name}
              isShowLabel={formData.name}
              variant={"outlined"}
              inputStyles={{
                height: "40px",
                padding: "12px 16px",
                fontSize: "16px",
                lineHeight: "20px",
                "::placeholder": {
                  color: "#665A56",
                  opacity: "1",
                  fontSize: "16px",
                  lineHeight: "20px",
                },
              }}
            />

            <StyledInput
              label={"Company"}
              name={"company"}
              onChange={(event) =>
                setFormData({ ...formData, company: event.target.value })
              }
              placeholder={"Company"}
              value={formData.company}
              isShowLabel={formData.company}
              variant={"outlined"}
              inputStyles={{
                height: "40px",
                padding: "12px 16px",
                fontSize: "16px",
                lineHeight: "20px",
                "::placeholder": {
                  color: "#665A56",
                  opacity: "1",
                  fontSize: "16px",
                  lineHeight: "20px",
                },
              }}
            />
            <StyledInput
              label={"Email"}
              name={"email"}
              onChange={(event) =>
                setFormData({ ...formData, email: event.target.value })
              }
              placeholder={"Email"}
              value={formData.email}
              isShowLabel={formData.email}
              variant={"outlined"}
              error={"Please enter a valid email address"}
              isError={!isValidEmail}
              inputProps={{
                pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}",
              }}
              inputStyles={{
                height: "40px",
                padding: "12px 16px",
                fontSize: "16px",
                lineHeight: "20px",
                "::placeholder": {
                  color: "#665A56",
                  opacity: "1",
                  fontSize: "16px",
                  lineHeight: "20px",
                },
              }}
            />
            <Box width={"100%"}>
              <StyledButton
                sx={{
                  width: "100%",
                  height: "40px",
                  padding: "12px 30px",
                  fontSize: "16px",
                }}
                color={"buttonPrimary"}
                variant={"contained"}
                label={"Request demo"}
                onClick={async () => {
                  const validate = validateEmail(formData.email);
                  if (formData.email && validate) {
                    await sendForm();
                  }
                  setIsValidEmail(validate);
                }}
              />
            </Box>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};

export default RequestDemoForm;
