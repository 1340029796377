import React from "react";
import { Helmet } from "react-helmet";

import { Box, Stack, useTheme } from "@mui/material";

const styles = {
  title: { fontSize: "24px" },
  ul: { fontSize: "16px" },
};
export default function Terms() {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>PartnerElement - Terms of service</title>
        <link rel="icon" href="/favicon.ico" sizes="any" />
      </Helmet>
      <Box
        sx={{
          width: "100%",
          background: "#F5F4F2",
          minHeight: "100vh",
          color: theme.palette.text.neutral,
          fontFamily: "CoFo Sans",
          fontSize: "18px",
          ul: {
            margin: "0",
            padding: "0 0 0 20px",
            li: {
              marginBottom: "4px",
              "last-of-type": { marginBottom: "0" },
            },
          },
        }}
      >
        <Box
          sx={{
            width: "calc(50% - 10.8%)",
            padding: "120px 10.8% 228px 10.8%",
            "@media (max-width: 1240px)": {
              width: "calc(75% - 4.3%)",
              padding: "120px 4.3% 228px 4.3%",
            },
            "@media (max-width: 800px)": {
              width: "calc(100% - 8.6%)",
              padding: "120px 4.3% 228px 4.3%",
            },
          }}
        >
          <Box
            sx={{ fontSize: "40px", fontWeight: "400", marginBottom: "36px" }}
          >
            Terms of Service
          </Box>
          <Stack
            direction={"column"}
            gap={"24px"}
            sx={{ div: { wordBreak: "break-word" } }}
          >
            <Box>Published: July 17, 2023</Box>
            <Box>
              These Terms of Service{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                (&ldquo;Terms&rdquo;
              </Box>
              ,{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                &ldquo;Terms of Service&rdquo;
              </Box>
              , or{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                &ldquo;Agreement&rdquo;
              </Box>
              ) are effective as of the Effective Date and is a contract by and
              between PartnerElement Inc., a Delaware corporation (
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                &ldquo;PartnerElement&rdquo;
              </Box>
              ,{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                &ldquo;Company&rdquo;
              </Box>
              ,{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                &ldquo;us&rdquo;
              </Box>
              ,{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                &ldquo;we&rdquo;
              </Box>
              , or{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                &ldquo;our&rdquo;
              </Box>
              ), and the client set forth on the Order Form or otherwise
              accessing and using the Service (
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                &ldquo;Client&rdquo;
              </Box>
              ,{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                &ldquo;you&rdquo;
              </Box>{" "}
              or{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                &ldquo;your&rdquo;
              </Box>
              ). These Terms include the provisions set forth in this document,
              in the PartnerElement{" "}
              <Box
                sx={{ textDecoration: "underline" }}
                component={"a"}
                href="https://partnerelement.com/privacy"
                target={"_blank"}
                rel="noreferrer"
              >
                Privacy Policy
              </Box>{" "}
              and, if indicated in the Order Form, the Data Processing Addendum
              (please contact{" "}
              <Box
                sx={{ textDecoration: "underline" }}
                component={"a"}
                href="mailto:privacy@partnerelement.com"
                target={"_blank"}
                rel="noreferrer"
              >
                privacy@partnerelement.com
              </Box>{" "}
              for the latest Data Processing Addendum) (all of which shall be
              incorporated by references herein to these Terms, Terms of
              Service, or this Agreement). Certain capitalized terms used in
              this Agreement are defined in the{" "}
              <Box
                sx={{ textDecoration: "underline" }}
                component={"a"}
                href="#Definitions"
                rel="noreferrer"
              >
                Definitions
              </Box>{" "}
              section of these Terms. If you do not agree to these Terms or you
              are not authorized to enter into this Agreement on behalf of
              Client, you are prohibited from accessing or using the Service.
            </Box>
            <Box>
              By signing an Order Form or other contract that references these
              Terms or clicking a box or otherwise indicating your acceptance of
              these Terms online when requested in connection with obtaining
              Service from us (as applicable and whichever first occurs), Client
              agrees to be legally bound by these Terms. Any individual who
              takes action to agree to these Terms on behalf of an organization
              (including any legal entity) is considered to be the initial
              Administrator and is (i) deemed to represent and warrant to us
              that they are authorized to agree to these Terms on behalf of such
              organization and (ii) confirms that such organization understands
              and agrees to be bound by these Terms. If you have entered into
              another contract with PartnerElement with respect to the Service,
              or under which the Service is made available to you, and there is
              a conflict between such separate contract and these Terms, such
              separate contract shall prevail with respect to such conflict if
              and to the extent it expressly provides that it shall govern.
            </Box>
            <Box sx={styles.title}>1. The Service</Box>
            <Box>
              <Box sx={styles.title}>1.1 Service</Box>
              <Box>
                PartnerElement offers an online hosted platform and certain
                proprietary software and related services (collectively, the{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  &ldquo;Service&rdquo;
                </Box>
                ) as more specifically described in an Order Form referencing
                these Terms and our Documentation, which Service may be provided
                to you on a trial or paid basis.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>1.2 Trial Period</Box>
              <Box>
                If Client is given the right to evaluate the Service on a trial
                basis without paying any Fees (
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  &ldquo;Trial Service&rdquo;
                </Box>
                ), PartnerElement will enable Client to access and use the Trial
                Service until the earlier of (i) the end of the free trial
                period, or (ii) the start date of any subscription purchased by
                Client for the Service. Client acknowledges that the right to
                access and use the Trial Service is subject to these Terms, but
                shall be limited to Client&apos;s evaluation of the Service,
                should not be used to conduct actual business operations, and is
                provided to Client on an &ldquo;AS IS&rdquo; and
                &ldquo;AS-AVAILABLE&rdquo; basis without any warranties,
                indemnification, support, or liability of any kind, express or
                implied.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>1.3 Initiating Service</Box>
              <Box>
                In order to create the Client account, you will need to provide
                us certain information regarding Client and the Authorized
                Users, such as name, email address, billing information, and
                other information we reasonably request from time to time, which
                we refer to collectively as{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  &ldquo;Client Information&rdquo;
                </Box>
                . You must ensure that the Client Information is accurate and
                complete, and is updated in a timely manner when necessary. If
                the Client Information is incorrect, we may not be able to
                contact you, and we reserve the right to suspend or terminate
                your subscription. It is the Client&apos;s responsibility to
                protect the password for Client&apos;s Administrator account. We
                reserve the right to not provide Client&apos;s Administrator
                password to anyone unless we are satisfied, in our sole
                discretion, that the individual requesting the password is
                authorized by Client to obtain such password. You must notify us
                immediately of any unauthorized access to or use of the
                Administrator&apos;s or any other Authorized User&apos;s
                password, or the Service. You are responsible for all activities
                that occur under your subscription utilizing credentials
                provided to you and Authorized Users, whether or not you know
                about or authorize such actions.
              </Box>
            </Box>
            <Box sx={styles.title}>2. Access and Use of the Service</Box>
            <Box>
              <Box sx={styles.title}>2.1 Access and Use the Service</Box>
              <Box>
                Upon establishing an account, Client and its Authorized Users
                shall have the right during the Service Term to access and use
                the Service and utilize the Service&apos;s proprietary
                algorithms to generate distilled presentations of aggregated
                Client Data solely for Client&apos;s use in enhancing its own
                procurement management and communications, subject to and in
                accordance with these Terms. Client and its Administrator shall
                ensure that all Authorized Users comply with any Client policies
                and legal obligations relating to the disclosure of Client Data.
                We do not assume any responsibility for actions taken, or
                omissions by, Authorized Users when using the Service that may
                violate Client policies or legal obligations.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>
                2.2 Sharing Client Data; Third-Party Products
              </Box>
              <Box>
                We rely upon you and your Authorized Users to determine the
                appropriate scope of Client Data to be provided to us, and the
                mode of transmitting such Client Data to us, in order to enable
                us to provide the Service. We understand that certain Client
                Data is stored in third-party products and services you utilize
                in the operation of your business (
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  &ldquo;Third-Party Products&rdquo;
                </Box>
                ), and that you may elect to enable our access to such Client
                Data via integrations with or other data transfers from such
                Third-Party Products. PartnerElement shall use commercially
                reasonable efforts to enable our extraction of Client Data from
                the Third-Party Products in a manner that is efficient for you
                and us, but we reserve discretion to determine whether or not we
                will engage directly with any particular Third-Party Products.
                We accept no responsibility or liability, and offer no refunds
                for Fees paid, if any Client Data cannot be delivered to us in a
                manner reasonably acceptable to us. You acknowledge and agree
                that, as between you and PartnerElement, you shall be solely
                responsible (through the actions of your Administrator and
                Authorized Users) for: (i) configuring any tools to select the
                scope and content of Client Data for transfer to the Service;
                (ii) the accuracy and completeness of any Client Data provided
                to us from any Third-Party Products; and (iii) ensuring that any
                extraction or delivery of Client Data between our Service and
                Third-Party Products is compliant with such Third-Party
                Product&apos;s contractual terms and use policies.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>2.3 Prohibited Uses</Box>
              <Box>
                Neither Client nor Authorized Users shall sell, resell, license,
                sublicense, distribute, rent, lease, or otherwise commercially
                exploit the Service except as expressly provided for by this
                Agreement. Client and its Authorized Users will not, directly or
                indirectly, nor permit any third party to: (i) send any
                unsolicited or unauthorized advertising, promotional materials,
                email, junk mail, spam, chain letters, or other form of
                solicitation; (ii) send or store in the Service any personal
                health data, credit card data, personal financial data, or other
                such sensitive data; (iii) directly or indirectly use the
                Service for the purpose of building a competitive product,
                timesharing, or otherwise providing any benefit from the Service
                to a third party; (iv) avoid, bypass, remove, deactivate,
                impair, descramble, or otherwise circumvent any technological
                measure implemented by PartnerElement or any of
                PartnerElement&apos;s providers or any other third party
                (including another user) to protect the Service or Client Data;
                (v) attempt to probe, scan, or test the vulnerability of any
                PartnerElement systems or to breach the security or
                authentication measures of PartnerElement systems; (vi) use or
                launch any automated system, including &ldquo;robots&rdquo;,
                &ldquo;spiders&rdquo;, &ldquo;crawlers&rdquo;, &ldquo;data
                mining&rdquo;, &ldquo;extraction tools&rdquo;, or &ldquo;offline
                readers&rdquo; or any other functionality that sends more
                requests to our servers in a given period of time than a human
                can reasonably produce in the same period by using a
                conventional browser; (vii) use the Service in any manner that
                damages, disables, overburdens, or impairs any of our websites,
                servers, or otherwise interferes with any other party&apos;s use
                of the Service or in a way intended to work around any technical
                limitations or usage limits; (viii) reverse engineer, decompile,
                disassemble, or otherwise create, attempt to create, or derive
                the source code from any software used in providing the Service;
                (ix) access the Service other than through our interface; or (x)
                use the Service in violation of any applicable law, for
                fraudulent or illegal activities, or outside the scope expressly
                permitted hereunder. It is the Administrator&apos;s
                responsibility to ensure that all Authorized Users are aware of,
                and instructed to comply with, these Terms. Client shall be
                responsible for all activities conducted under its Authorized
                User&apos;s logins to the Service.
              </Box>
            </Box>
            <Box sx={styles.title}>3. Providing the Service</Box>
            <Box>
              <Box sx={styles.title}>3.1 Availability</Box>
              <Box>
                PartnerElement will endeavor to make the Service available 24
                hours a day, 7 days a week, excluding such periods when the
                Service may be unavailable due to necessary maintenance,
                updates, or other factors, and unanticipated downtime. We
                anticipate that Service unavailability will be infrequent and of
                very limited duration and should we know that there will be any
                extended unavailability, we will use reasonable efforts to
                provide advance notice to you. PartnerElement does not guarantee
                or warrant that the Service will be available at all times or
                will continue to be offered indefinitely. Client acknowledges
                and accepts that the Service is hosted and only accessible via
                use of the Internet and that interruptions, outages, and other
                connectivity problems inherent to the Internet that are outside
                PartnerElement&apos;s control may result in delays or
                unavailability of access to the Service for which we shall not
                have any liability. Client&apos;s Administrator shall have the
                right to enable, and to limit or terminate, an Authorized
                User&apos;s access and use the Service.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>3.2 Support</Box>
              <Box>
                The Administrator of your account may request information or
                assistance from our support team via the contact form available
                at{" "}
                <Box
                  sx={{ textDecoration: "underline" }}
                  component={"a"}
                  href="https://url.emailprotection.link/?b-ZQk_QkiHxykd4ECNuPfGGkjojcg5VDPkVkSZ9EQSb31gDSQr7zDVFggGRo2MvAG9r2cf-uxlsTrRKNmyCVtKw~~"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  app.partnerelement.com
                </Box>{" "}
                or by email to{" "}
                <Box
                  sx={{ textDecoration: "underline" }}
                  component={"a"}
                  href="mailto:support@partnerelement.com"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  support@partnerelement.com
                </Box>
                . Please make sure to contact us using the Administrator’s email
                registered with your account. We will use commercially
                reasonable efforts to respond and assist, but please note that
                we shall not have any obligation to provide specific support to
                you with respect to the use of the Service unless specifically
                provided in an Order Form. Additionally, if you experience any
                failure of the Service to operate in accordance with its
                specifications, you must provide us with sufficient information
                so that we may replicate such failure before we can attempt to
                address an issue. PartnerElement will endeavor to respond to any
                specifically described error report within twenty-four (24)
                hours. We may elect to request that you cooperate and work
                closely with PartnerElement personnel to reproduce any reported
                error, including conducting diagnostic or troubleshooting
                activities that may be requested, and any delay or failure to
                cooperate may delay any remediation of the error. We may also
                request your Administrator or other affected Authorized Users to
                approve access to their user accounts, computers, and/or your
                computer network, for troubleshooting purposes.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>3.3 Enhancements</Box>
              <Box>
                While we expect to provide updates and other enhancements to the
                Service from time to time, and guidance on how to use the
                Service as it is updated, you should not rely upon the release
                of any enhancements occurring when deciding to subscribe. Your
                decision to subscribe should be based upon the functionality
                available when you are making that decision, and not on
                potential future functionality.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>3.4 PartnerElement Service Providers</Box>
              <Box>
                PartnerElement engages third-parties to provide technical or
                other services utilized by us in performing the Service (the{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “PartnerElement Service Providers”
                </Box>
                ), and you hereby agree that such involvement by these
                PartnerElement Service Providers is acceptable. Please see our{" "}
                <Box
                  sx={{ textDecoration: "underline" }}
                  component={"a"}
                  href="https://partnerelement.com/privacy"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Privacy Policy
                </Box>{" "}
                to understand the extent to which we provide any third party
                with access to Client Data (as defined below).
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>3.5 Temporary Suspension</Box>
              <Box>
                PartnerElement may suspend access to or use of all or any part
                of the Service without incurring any liability, if: (i) we
                receive a judicial or other governmental demand or order,
                subpoena, or law enforcement request that requires us to do so;
                or (ii) we reasonably determine that (a) there may be an
                imminent threat to the security of the Service or Client Data
                therein, (b) login credentials may have been compromised, (c)
                someone with access credentials of an Authorized User has
                violated the restrictions on access or use of the Service, or
                (d) suspension of access is necessary during any maintenance of
                the Service. Absent an uncured breach of these Terms, any
                suspension shall be as short as reasonably possible and in no
                event longer than ten (10) days. Our suspension rights do not
                limit any of our other rights or remedies, whether at law, in
                equity or under this Agreement.
              </Box>
            </Box>
            <Box sx={styles.title}>4. Commercial Terms</Box>
            <Box>
              <Box sx={styles.title}>4.1 Fees</Box>
              <Box>
                Except for any free trial period, or other exception we may
                grant from time to time as specified in an Order Form, Client
                must pay the subscription price specified for the Service in the
                Order Form (
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Fees”
                </Box>
                ). Payment obligations are non-cancelable and, except as
                expressly stated in these Terms, Fees paid are non-refundable.
                If we agree to invoice Client, full payment must be received
                within thirty (30) days from Client’s receipt of the invoice
                unless otherwise provided in an Order Form. Fees stated are
                exclusive of any taxes, levies, duties, or similar governmental
                assessments of any nature, including, for example, value-added,
                sales, use, or withholding taxes, assessable by any jurisdiction
                (collectively,{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Taxes”
                </Box>
                ). Client will be responsible for paying all Taxes associated
                with its subscription, except for those taxes based on
                PartnerElement’s net income.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>4.2 Autorenewal</Box>
              <Box>
                Unless an Order Form provides otherwise, (i) all subscriptions
                automatically renew (without the need to execute a renewal Order
                Form) for an additional period equal to the preceding term; and
                (ii) subject to any price increase (see below), the pricing
                during any automatic renewal term will remain the same as it was
                during the immediately prior term; provided, however, that
                either Client or PartnerElement may elect to terminate Client’s
                access and use rights as of the end of the then current
                subscription term by providing not less than thirty (30) days’
                notice prior to such termination date.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>4.3 Payment Methods</Box>
              <Box>
                PartnerElement accepts certain debit and credit cards as well as
                other forms of electronic transfers as acceptable payment
                methods for the Service (each, an{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Accepted Payment Method”
                </Box>
                ), subject to the procedures and rules contained in these Terms
                and any applicable contract with the provider of the Accepted
                Payment Method. Accepted Payment Methods may vary by country or
                by certain features of the Service and may change from time to
                time. In order to facilitate the charging and payment for your
                subscription, Client agrees that we have permission to retain
                and/or share with financial institutions and payment processing
                firms (including any institutions or firms we retain in the
                future) information regarding your purchase and your submitted
                payment information in order to process your payment, and to use
                your Client Information to provide you with notices and
                disclosures relating to renewals and recurring charges.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>4.4 Price Changes</Box>
              <Box>
                PartnerElement may change the price of any Service from time to
                time, and add new fees and charges for certain features or to
                reflect a change in business or legal rules, but we will provide
                you with advance notice of changes in recurring subscription
                fees. Any increase in charges for the same Service shall not
                apply until the expiration of the then current subscription
                term, and would become effective no sooner than the next time
                you would be charged for such Service. If you do not agree to
                pay the new price or other applicable charges, you may elect not
                to renew the Service subscription before the price change goes
                into effect, which cancellation would be effective at the
                expiration of your then current subscription term.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>4.5 Paying our Payment Processor</Box>
              <Box>
                When you pay for the Service, you are granting PartnerElement
                the irrevocable right and permission with respect to such
                purchase to provide your personal data and payment information
                to any third-party payment processor we contract with (such as
                Stripe and/or one of its financial service providers) on your
                behalf, and to grant such firm(s) (and/or one of their
                respective financial service providers) the rights to collect,
                use, retain, and disclose such data and information. In
                addition, you authorize PartnerElement to (i) obtain and verify
                your identity as necessary to complete financial transactions,
                and (ii) determine your eligibility and authority to complete
                such purchase. Please note that your obligation for payment to,
                and relationship with, such payment processor is a contractual
                matter between you and such third-party; PartnerElement is not a
                party to, or responsible on account of, such contract. While we
                select our payment processors carefully and enter into detailed
                agreements imposing performance obligations (including
                confidentiality) on them, we cannot and do not guarantee their
                performance. We encourage you to provide any feedback regarding
                any payment processor with which you do business to us, as we
                value and use your input in determining whether to continue or
                end such relationships. If you have any concerns or problems
                with a payment processor, please contact us at
                support@partnerelement.com as promptly as you can.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>
                4.6 Changes to Payment Methods and Account Information
              </Box>
              <Box>
                Client is responsible for ensuring that its Client Information,
                including all payment information (address, your bank’s routing
                number, account number, debit or credit card number, and
                expiration date), is accurate and up to date. You may change
                this information at any time by contacting
                support@partnerelement.com. If you have any difficulty making
                any change to the payment method, you must notify us by
                contacting support@partnerelement.com and demonstrating to our
                satisfaction your authority to change the payment method used
                for the Service. If your credit card expires or the information
                is otherwise invalid, or you or another party controlling the
                payment method make changes to your billing account too close in
                time to when we charge for the Service subscription (so we
                cannot reasonably act on your changes prior to billing), we will
                probably not be aware of this, and we will bill the current
                charges to the card we had on record; the continuation of your
                Service subscription constitutes your authorization for us to do
                so, and you remain responsible for any uncollected amounts and
                any charges incurred by you or us as a result of billing to an
                invalid card or other payment method.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>
                4.7 Costs of Collection; Credit Card Chargebacks
              </Box>
              <Box>
                In addition to the Fees, Client agrees to pay any reasonable
                costs we incur to collect any unpaid or past due amounts,
                including reasonable attorneys’ fees and other associated costs.
                Delinquent payments will bear interest at the rate of 1.5% per
                month or the highest rate permitted under applicable law,
                whichever is less, from the payment due date until paid in full.
                If you purchase a Service subscription with a credit card and
                then subsequently request your credit card issuer to reverse
                that payment, PartnerElement may be charged a fee. Accordingly,
                in order to enable you to pay fees with a credit card, you
                acknowledge and agree that we reserve the right to suspend your
                ability to use the Service until such time as you reimburse us
                the amount of the fee we were charged by the card issuer.
              </Box>
            </Box>
            <Box sx={styles.title}>5. Use of Beta Service</Box>
            <Box>
              We may from time to time make available a “beta” or other
              pre-release implementation, or module, of our Service (a{" "}
              <Box component={"span"} sx={{ fontWeight: "bold" }}>
                “Beta Service”
              </Box>
              ). If you elect to use a Beta Service, you must agree to the
              following specific requirements because it will be in the early
              development / testing phase and may contain as-yet undiscovered
              defects, and a primary purpose of a release of any Beta Service is
              to obtain feedback on performance for improving, and evaluating
              whether or not to provide a future general release to the Service:
            </Box>
            <Box component={"ul"} sx={styles.ul}>
              <Box component={"li"}>
                You are advised to safeguard important data, to use caution and
                not rely in any way on the correct functioning or performance of
                the Beta Service, and avoid use in the conduct of actual
                business operations;
              </Box>
              <Box component={"li"}>
                You acknowledge and agree that you accept all risks for
                accessing and using the Beta Service, and that the Beta Service
                is being provided on an “AS-IS” and “AS-AVAILABLE” basis,
                without any warranty or assurance whatsoever concerning its
                reliability, stability, or performance, and without any
                indemnification, support, or liability of any kind, express or
                implied; and
              </Box>
              <Box component={"li"}>
                You agree that the features and functionality of the Beta
                Service are confidential and you shall not disclose any
                information relating to such Beta Service unless we give you
                specific written permission to do so.
              </Box>
            </Box>
            <Box sx={styles.title}>6. Ownership; Data Rights</Box>
            <Box>
              <Box sx={styles.title}>6.1 PartnerElement Ownership</Box>
              <Box>
                PartnerElement and its licensors exclusively own all right,
                title, and interest in and to the Service, including all
                proprietary algorithms, technologies, reporting methodologies,
                and machine learning occurring as a result of the use of the
                Service by you and your Authorized Users, the Documentation, and
                any enhancements, derivative works, or improvements to the
                Service and Documentation. You acknowledge that the Service is
                protected by copyright, trademark, and other laws of the United
                States and foreign countries.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>6.2 Client Ownership</Box>
              <Box>
                Client owns all right, title, and interest in and to the Client
                Data, subject only to the limited license expressly granted to
                PartnerElement in these Terms.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>6.3 License to Client Data</Box>
              <Box>
                Client hereby grants to PartnerElement a non-exclusive and
                non-transferable (except as provided in Section 15.3) license to
                access, capture, copy, store, transmit, process, use, and
                display the Client Data as necessary to provide and improve the Service and
                otherwise comply with its legal obligations relating thereto.
                Client may submit a written request for the deletion of Client
                Data at any time prior to PartnerElement’s scheduled data
                retention destruction and PartnerElement will certify the same
                in writing to Client. PartnerElement will handle Client Data in
                accordance with these Terms and, if applicable, the Data
                Processing Addendum (please contact{" "}
                <Box
                  sx={{ textDecoration: "underline" }}
                  component={"a"}
                  href="mailto:privacy@partnerelement.com"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  privacy@partnerelement.com
                </Box>{" "}
                for the latest Data Processing Addendum).
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>6.4 Feedback</Box>
              <Box>
                Client and its Authorized Users may from time to time provide
                PartnerElement suggestions or comments for enhancements or
                improvements, new features or functionality or other feedback
                relating to the Service (
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Feedback”
                </Box>
                ). PartnerElement will have full discretion on whether, when,
                and how to utilize Feedback in the development of the Service,
                in developing any other product or service, or sharing with
                another party, and any such use, incorporation, and/or
                disclosure of Feedback shall be permitted without attribution,
                permission, or any compensation paid to you.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>6.5 Usage Data</Box>
              <Box>
                Notwithstanding anything to the contrary in these Terms,
                PartnerElement may collect and analyze statistical and
                analytical data and other information relating to the access,
                provision, use, and performance of various aspects of the
                Service and related systems and technologies (
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Usage Data”
                </Box>
                ), and PartnerElement will be free (during and after the Term)
                to (i) use Usage Data to improve and enhance the Service and for
                other development, diagnostic, and corrective purposes in
                connection with the Service and other PartnerElement product
                offerings, and (ii) disclose Usage Data in connection with its
                business provided the Usage Data so disclosed is in aggregated
                or other de-identified form so that no association with Client
                or any Authorized Users is possible.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>6.6 Transaction Data</Box>
              <Box>
                Notwithstanding anything to the contrary in these Terms,
                PartnerElement may collect and analyze data obtained from use of
                the Service by Client consisting of categories of products and
                services sold by certain vendors, transaction volume, geographic
                information for product and service deliveries, and vendor
                performance information (
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Transaction Data”
                </Box>
                ), and PartnerElement will be free (during and after the Term)
                to (i) use Transaction Data to improve and enhance information
                available within the Service and for other lawful business
                purposes, provided the Transaction Data so disclosed is in
                aggregated or other de-identified form so that no Transaction
                Data may be associated with Client or any Authorized Users.
              </Box>
            </Box>
            <Box sx={styles.title}>
              7. Confidentiality; Privacy; Data Security
            </Box>
            <Box>
              <Box sx={styles.title}>
                7.1 Confidential Information and Disclosure
              </Box>
              <Box>
                Each party agrees as follows: (i) it will not disclose the
                Confidential Information (as defined below) of the other party
                to anyone except its employees, contractors, third-party service
                providers and advisors who have a need to know and who have been
                advised of and have agreed to treat such information in
                accordance with the terms of this Agreement and (ii) it will not
                use or reproduce the other party’s Confidential Information for
                any purpose other than performing its obligations or exercising
                its rights as described herein. Each party will be liable for
                the acts and omissions of its representatives with respect to
                the other party’s Confidential Information.{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Confidential Information”
                </Box>{" "}
                means all information, disclosed by one party (the{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “disclosing party”
                </Box>
                ) to the other (the{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “receiving party”
                </Box>
                ), related to the business, products, solutions, services or
                operations of the disclosing party or a third party that has
                been identified as confidential or that by the nature of the
                information ought reasonably to be treated as confidential,
                including (subject to the foregoing exclusions) the Client
                Content. Client Confidential Information includes all Client
                Data. PartnerElement Confidential Information includes
                non-public information regarding features, functionality, and
                performance of the Service. Confidential Information shall not
                include information that is (i) generally known to the public
                without breach of these Terms, (ii) obtained by a party from
                another source not known to have an obligation of
                confidentiality to the other party, (iii) at the time of
                disclosure to a party is already in the possession of such party
                free of any confidentiality obligation, or (iv) independently
                developed by a party without reference to any Confidential
                Information of the other party. Notwithstanding anything to the
                contrary herein, a receiving party shall not be deemed to have
                violated this Section if it discloses Confidential Information
                in response to a bona fide subpoena, judicial order, or other
                lawful process issued by a court or governmental agency of
                competent jurisdiction that compels such disclosure. Before
                doing so, to the extent lawful, the receiving party will provide
                reasonable written notice to the disclosing party before any
                such disclosure so that the disclosing party may seek a
                protective order or other appropriate remedy to prevent or limit
                such disclosure. In any event, the receiving party will furnish
                only that portion of the Confidential Information that it is
                legally required to furnish.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>7.2 Privacy</Box>
              <Box>
                If indicated in the Order Form, the Data Processing Addendum
                sets forth the parties’ agreement with respect to the terms
                governing any processing of Personal Data by PartnerElement on
                Client’s behalf in providing the Service.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>7.3 Security</Box>
              <Box>
                PartnerElement shall utilize industry standard tools, and
                maintain appropriate administrative, physical, and technical
                safeguards to protect the security and integrity of the Service
                and Client Data, as more specifically described in
                PartnerElement{" "}
                <Box
                  sx={{ textDecoration: "underline" }}
                  component={"a"}
                  href="https://partnerelement.com/security"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Security
                </Box>
                , and shall not diminish the protections provided therein during
                the Service Term. Client will use industry-standard tools and
                commercially reasonable efforts to maintain the security and
                integrity of its systems that access or are integrated with the
                Service and will inform PartnerElement of any unauthorized
                access to or use of the Service, or any compromise in the
                security of any Authorized User’s login credentials.
              </Box>
            </Box>
            <Box sx={styles.title}>8. Term and Termination</Box>
            <Box>
              <Box sx={styles.title}>8.1 Service Term</Box>
              <Box>
                Client may access and use the Service during the Service Term as
                set forth in the Order Form, and as may be extended as a result
                of any automatic or other renewal of a subscription term,
                subject to Section 8.2.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>8.2 Termination</Box>
              <Box>
                Either party may terminate the Service Term and this Agreement
                immediately upon written notice (i) for any or no reason or (ii)
                in the event that the other party breaches any provision of the
                applicable Order Form or these Terms and fails to cure the
                breach within thirty (30) days of notice thereof from the
                non-breaching party.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>8.3 Effect of Termination</Box>
              <Box>
                Upon expiration or termination of the Service Term: (i) Client
                and all Authorized Users shall immediately cease accessing the
                Service, except solely to obtain any Client Data during the
                thirty (30) day period immediately following the termination
                date (the{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Wind-up Period”
                </Box>
                ); (ii) Client shall pay PartnerElement any unpaid Fees due
                according to these Terms; and (iii) each party shall promptly
                return to the other party, or at the other party’s written
                request, destroy all Confidential Information of the other
                party, except to the extent: (a) it is necessary to retain or
                use such Confidential Information in connection with the
                provision of any surviving obligations hereunder, (b) it is
                required by applicable law, or (c) it is retained in its
                backups, archives, and disaster recovery systems until it is
                deleted in the ordinary course and in accordance with applicable
                law, provided that all such retained Confidential Information
                remains subject to all confidentiality, security, and other
                applicable requirements of this Agreement. PartnerElement shall
                have no obligation to maintain or provide any Client Data and,
                unless legally prohibited, PartnerElement shall be entitled to
                delete all Client Data, after the expiration of the Wind-up
                Period. Client shall be responsible for full payment of Fees due
                for the entire Service Term, including any subscription period
                that would have extended beyond the termination date if Client
                terminates the Service Term pursuant to Section 8.2(i) or
                PartnerElement terminates the Service Term pursuant to Section
                8.2(ii). PartnerElement will refund Client a pro-rata portion of
                any prepaid Fees allocable to the subscription period after the
                termination date if PartnerElement terminates the Service Term
                pursuant to Section 8.2(i) or Section 10.1, or Client terminates
                the Service Term pursuant to Section 8.2(ii), Section 15.5, or
                Section 15.9. Sections 6 (other than 6.3), 10, 11, 12, 13, and
                15 shall survive termination or expiration of the Agreement, and
                any other provision that, by its nature and context is intended
                to survive termination of the Agreement, shall survive.
              </Box>
            </Box>
            <Box sx={styles.title}>9. Warranty and Disclaimer</Box>
            <Box>
              <Box sx={styles.title}>9.1 Contracting Warranties</Box>
              <Box>
                PartnerElement and Client each represents and warrants to the
                other that: (i) if it is a legal entity, it is duly organized
                and validly existing under the laws of the jurisdiction in which
                it is organized; (ii) the individual entering into these Terms
                on its behalf has full power and authority, and has obtained all
                approvals, permissions, and consents necessary, to enter into
                this Agreement and obligate it to perform its obligations
                hereunder; (iii) this Agreement is legally binding upon it and
                enforceable in accordance with its terms; (iv) it shall comply
                with all applicable laws in connection with its performance or
                use of the Service; and (v) the execution and performance under
                these Terms does not and will not conflict with any agreement,
                instrument, judgment, or understanding, oral or written, to
                which it is a party or by which it may be bound.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>9.2 Service Warranty</Box>
              <Box>
                PartnerElement warrants to Client that during the Service Term:
                (i) the Service will be provided in conformity with industry
                standards in a professional and workmanlike manner and (ii)
                PartnerElement will provide the Service in a manner that is
                designed to be free of all viruses, worms, Trojan horses, and
                other malicious code the purpose of which is to permit
                unauthorized access to, or cause harm or impede in any manner
                any of Client’s computer systems or data (the{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Service Warranty”
                </Box>
                ). Non-substantial variations of performance from the published
                specifications in the Documentation do not constitute a breach
                of the Service Warranty or any right to assert a claim
                hereunder. The Service Warranty is void if the breach arises as
                a result of any modification of the Service by Client, an
                Authorized User, or any third party not authorized by
                PartnerElement to make such modification, or as a result of any
                Force Majeure Event. Client’s sole and exclusive remedy and
                PartnerElement’s entire obligation and liability with respect to
                any breach of the Service Warranty shall be, at PartnerElement’s
                sole option and expense, to either (a) provide a correction or
                other repair or modification of the Service that does not
                degrade the functionality of the Service, (b) provide a
                functionally equivalent substitute for the Service, or (c)
                terminate the Service Term. Any claim for a breach of the
                Service Warranty clams must be made by notice to PartnerElement
                within the Service Term.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>9.3 Disclaimer</Box>
              <Box>
                EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS, TO THE MAXIMUM
                EXTENT PERMITTED BY LAW, THE SERVICES ARE PROVIDED “AS IS” AND
                “AS AVAILABLE,” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
                IMPLIED. WITHOUT LIMITING THE FOREGOING, PARTNERELEMENT
                EXPLICITLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY, FITNESS
                FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, OR NON-INFRINGEMENT,
                AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF
                TRADE. PARTNERELEMENT MAKES NO WARRANTY THAT THE SERVICES WILL
                BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS OR
                THAT THE SERVICES WILL MEET (OR ARE DESIGNED TO MEET) CLIENT’S
                BUSINESS REQUIREMENTS.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>9.4 Third-Party Products</Box>
              <Box>
                The Service is designed to work with certain Third-Party
                Products, but we do not control the performance or continued
                availability and/or integration of Third-Party Products.
                Accordingly, you acknowledge and agree that (i) we are not
                responsible for the performance of Third-Party Products, even if
                we reference or provide links thereto, including, without
                limitation, their use or treatment of Client Data therein; (ii)
                we are not responsible or liable for any content or other
                materials generated by such Third-Party Products; (iii) we are
                not responsible for any technical inability to access Client
                Data in such Third-Party Products via our Service; and (iv) we
                shall not be responsible or liable, directly or indirectly, for
                any damage or loss caused or alleged to be caused by or in
                connection with use of or reliance on any Third-Party Products.
              </Box>
            </Box>
            <Box sx={styles.title}>10. Indemnification</Box>
            <Box>
              <Box sx={styles.title}>10.1 PartnerElement Indemnification</Box>
              <Box>
                PartnerElement will defend Client from any claims, actions, and
                proceedings brought against Client by a third party alleging
                that the Service, as provided by PartnerElement to Client and
                used in accordance with these Terms infringes any patent,
                copyright, or trademark or misappropriates a trade secret of any
                third party (each, an{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Infringement Claim”
                </Box>
                ). PartnerElement will indemnify Client from all fines, damages,
                and costs finally awarded against Client by a court of competent
                jurisdiction or a government agency, or agreed to in a
                settlement, arising from an Infringement Claim, including any
                reasonable out-of-pocket expenses (including attorneys’ fees)
                incurred by Client. If the Service becomes or in
                PartnerElement’s opinion is likely to become subject to an
                Infringement Claim, PartnerElement may in its sole discretion
                and expense: (i) modify the Service to render it non-infringing
                or provide a replacement service, provided that such
                modification or replacement shall not degrade the functionality,
                operation, or performance of the Service; (ii) obtain a license
                for Client’s continued use of the Service; or (iii) terminate
                the Service Term. PartnerElement’s obligations pursuant to this
                Section 10.1 will not apply to the extent that such claim is
                caused by or results from: (a) any access or use of the Service
                in violation of these Terms or any Documentation; (b) any
                modification to the Service made by a party other than
                PartnerElement or a third party authorized by PartnerElement to
                make such modification; (c) the failure of Client to use any
                reasonable corrections or modifications to the Service made
                available by PartnerElement (provided use of such corrections or
                modifications does not materially degrade the Service); or (d)
                the combination of the Service with other products, services,
                processes, or technologies (where the infringement would have
                been avoided but for the combination).
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>10.2 Client Indemnification</Box>
              <Box>
                Client will defend PartnerElement from any claims, actions and
                proceedings brought against PartnerElement by a third party
                arising out of, related to, or in connection with any Client
                Data uploaded into the Service or otherwise provided to
                PartnerElement in violation of any applicable law or such third
                party’s rights therein.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>10.3 Indemnification Process</Box>
              <Box>
                The party seeking indemnity under this Section 10 (the
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Indemnitee”
                </Box>
                ) must provide the party obligated to indemnify (the
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Indemnitor”
                </Box>
                ) the following: (i) prompt notice of any claim for which the
                Indemnitee intends to seek indemnification within a reasonable
                period after learning of the claim (provided that any delay in
                providing the notice will relieve the indemnifying party of its
                indemnification obligations only to the extent that the delay
                prejudices the indemnifying party); (ii) sole control over the
                defense and settlement of the claim, provided that the
                Indemnitor shall not enter into any settlement requiring payment
                of money or other affirmative act (or inaction) by the
                Indemnitee without the Indemnitee’s prior written consent, which
                consent shall not be unreasonably withheld, delayed, or
                conditioned; and (iii) all cooperation and assistance reasonably
                requested by the Indemnitor, at Indemnitor’s expense for
                reasonable out-of-pocket costs. The Indemnitee may participate
                in the defense of the claim at its sole expense.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>10.4 Exclusive Remedy</Box>
              <Box>
                This section 10 states the Indemnitor’s sole liability to, and
                the Indemnitee’s exclusive remedy against, the other party for
                any type of claim described in this Section 10.
              </Box>
            </Box>
            <Box sx={styles.title}>11. Limitation of Liability</Box>
            <Box>
              <Box sx={styles.title}>11.1 CONSEQUENTIAL DAMAGES EXCLUSION</Box>
              <Box>
                EXCEPT FOR (I) A PARTY’S INDEMNIFICATION OBLIGATIONS UNDER
                SECTION 10, OR LIABILITY ARISING FROM ITS GROSS NEGLIGENCE,
                WILLFUL MISCONDUCT, OR FRAUD AND (II) CLIENT’S OBLIGATIONS TO
                PAY FEES, NEITHER CLIENT NOR PARTNERELEMENT (OR ANY OTHER PARTY
                INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICE) WILL
                BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR
                CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA,
                LOST GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE, OR SYSTEM
                FAILURE, OR THE COST OF OBTAINING ANY SUBSTITUTE SERVICE,
                ARISING OUT OF OR IN CONNECTION WITH THE USE OF OR INABILITY TO
                USE THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT
                (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL
                THEORY, AND WHETHER OR NOT SUCH PARTY HAS BEEN INFORMED OF THE
                POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH
                HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME
                JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE
                LIMITATION MAY NOT APPLY IN ALL CIRCUMSTANCES.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>11.2 LIMITATION OF LIABILITY</Box>
              <Box>
                EXCEPT FOR (I) EACH PARTY’S INDEMNIFICATION OBLIGATIONS UNDER
                SECTION 10, OR LIABILITY ARISING FROM ITS GROSS NEGLIGENCE,
                WILLFUL MISCONDUCT, OR FRAUD, AND (II) CLIENT’S OBLIGATIONS TO
                PAY FEES, EACH PARTY’S MAXIMUM AGGREGATE LIABILITY HEREUNDER
                WILL NOT EXCEED THE GREATER OF THE TOTAL AMOUNT OF FEES PAID OR
                PAYABLE TO PARTNERELEMENT UNDER THE ORDER FORM DURING THE TWELVE
                (12) MONTH PERIOD IMMEDIATELY PRECEDING THE DATE ON WHICH THE
                LIABILITY AROSE (THE{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “LIABILITY CAP”
                </Box>
                ); PROVIDED, HOWEVER, THAT A PARTY’S INDEMNIFICATION OBLIGATIONS
                SHALL NOT EXCEED TWO TIMES (2X) THE AMOUNT OF THE LIABILITY CAP.
              </Box>
            </Box>
            <Box sx={styles.title}>12. Notices</Box>
            <Box>
              All notices required or permitted to be given by one party to the
              other in connection with this Agreement shall be sufficient if in
              writing and delivered via email, with any email sent to Client to
              be delivered to the then-current email address of the
              Administrator provided to PartnerElement and with any email sent
              to PartnerElement to be delivered to legal@partnerelement.com, or
              to such other address as PartnerElement has designated by notice
              to Client. Client hereby consents to receive electronic
              communications related to its subscription and use of the Service.
              Notices delivered via email will be considered delivered and
              effective as of the time it is sent.
            </Box>
            <Box sx={styles.title}>13. Dispute Resolution</Box>
            <Box>
              <Box sx={styles.title}>13.1 Negotiation</Box>
              <Box>
                The parties agree to use commercially reasonable efforts to
                settle any dispute through negotiation with each other before
                initiating any arbitration process provided herein (at least for
                sixty (60) days).
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>
                13.2 Alternative Dispute Resolution Process
              </Box>
              <Box>
                Unless you are subject to the Mandatory Arbitration Provisions
                set out below, and subject to any applicable laws, if a claim
                arises between Client and PartnerElement where the total value
                of such claim is less than US$10,000, the party initiating the
                claim may elect to have the dispute resolved pursuant to a
                binding arbitration process that does not require attendance in
                person. This “Alternative Dispute Resolution Process” shall be
                initiated by either of us sending notice to the other, in which
                event Client and PartnerElement agree to use our reasonable
                efforts to agree within thirty (30) days upon an individual or
                service to manage the Alternative Dispute Resolution Process
                (the{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Arbitration Manager”
                </Box>
                ) according to the following requirements: (i) neither party
                shall be required to attend any proceeding in person, (ii) the
                proceeding will be conducted via written submissions, telephone
                or online communications or as otherwise agreed upon, (iii) the
                fees for the Arbitration Manager will be borne equally by the
                parties or be submitted to the Arbitration Manager to determine
                as part of the dispute and (iv) the judgment rendered by the
                Arbitration Manager may be entered in any court of competent
                jurisdiction for enforcement. If you are a resident of the
                European Union (EU), please note that we offer this Alternative
                Dispute Resolution Process, but we cannot offer you the European
                Commission Dispute Platform as we do not have an establishment
                in the EU.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>13.3 Mandatory Arbitration Provisions</Box>
              <Box>
                If you reside in the United States or are otherwise subject to
                the US Federal Arbitration Act, you and PartnerElement agree to
                resolve any claims relating to these Terms or the Service -
                except any dispute relating to the enforcement or validity of
                intellectual property rights - through final and binding
                arbitration by a single arbitrator, except as set forth under
                Exceptions for Injunctive Relief below. This includes disputes
                arising out of or relating to interpretation or application of
                this “Mandatory Arbitration Provisions” section, including its
                enforceability, revocability, or validity. The Federal
                Arbitration Act governs the interpretation and enforcement of
                these Mandatory Arbitration Provisions. The{" "}
                <Box
                  sx={{ textDecoration: "underline" }}
                  component={"a"}
                  href="https://www.adr.org/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  American Arbitration Association
                </Box>{" "}
                (AAA) will administer the arbitration under its Commercial
                Arbitration Rules, as modified by these Mandatory Arbitration
                Provisions. You may review those rules and procedures, and
                obtain a form for initiating arbitration proceedings at the{" "}
                <Box
                  sx={{ textDecoration: "underline" }}
                  component={"a"}
                  href="https://www.adr.org/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  AAA’s website
                </Box>
                . The arbitrator will decide the substance of all claims in
                accordance with the laws of the State of California, including
                recognized principles of equity, and will honor all claims of
                privilege recognized by law. The arbitrator’s award shall be
                final and binding and judgment on the award rendered by the
                arbitrator may be entered in any court possessing jurisdiction
                over the parties, except for a limited right of appeal under the
                Federal Arbitration Act. The arbitration will be held in San
                Francisco, California, or any other location we agree to. If,
                however, the value of the relief sought is US$10,000 or less,
                either of us may elect to have the arbitration conducted by
                telephone or based solely on written submissions, which election
                shall be binding on us, subject to the arbitrator’s discretion
                to require an in-person hearing. Attendance at an in-person
                hearing may be made by telephone by you and/or us unless the
                arbitrator requires otherwise.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>13.4 Exception for Injunctive Relief</Box>
              <Box>
                Notwithstanding the provisions of Section 13.2 and 13.3, either
                the Client or PartnerElement may bring a lawsuit solely for
                injunctive relief to stop unauthorized use or abuse of the
                Service, or intellectual property infringement (for example,
                trademark, trade secret, copyright, or patent rights) without
                first engaging in arbitration or the informal dispute-resolution
                process described above.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>13.5 Class Action Waiver</Box>
              <Box>
                YOU MAY ONLY RESOLVE DISPUTES WITH US ON AN INDIVIDUAL BASIS,
                AND MAY NOT BRING A CLAIM AS A PLAINTIFF OR A CLASS MEMBER IN A
                CLASS, CONSOLIDATED, OR REPRESENTATIVE ACTION. CLASS
                ARBITRATIONS, CLASS ACTIONS, PRIVATE ATTORNEY GENERAL ACTIONS,
                AND CONSOLIDATION WITH OTHER ARBITRATIONS AREN’T ALLOWED. IF
                THIS SPECIFIC PARAGRAPH IS HELD UNENFORCEABLE, THEN THE ENTIRETY
                OF THIS “MANDATORY ARBITRATION PROVISIONS” SECTION WILL BE
                DEEMED VOID.
              </Box>
            </Box>
            <Box sx={styles.title} id={"Definitions"}>
              14. Definitions
            </Box>
            <Box>
              The following terms are defined for use in these Terms and in any
              Order Form:
            </Box>
            <Box>
              <Box sx={styles.title} component={"span"}>
                14.1{" "}
                <Box sx={{ fontWeight: "bold" }} component={"span"}>
                  “Administrator”
                </Box>
              </Box>{" "}
              <Box component={"span"}>
                means the individual who initiates a subscription for a Client,
                and any other individual that assumes the role of Administrator
                on behalf of a Client during the Service Term.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title} component={"span"}>
                14.2{" "}
                <Box sx={{ fontWeight: "bold" }} component={"span"}>
                  “Authorized User”
                </Box>
              </Box>{" "}
              <Box component={"span"}>
                means Client’s (i) employees and contractors solely for the
                purpose of providing services to Client.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title} component={"span"}>
                14.3{" "}
                <Box sx={{ fontWeight: "bold" }} component={"span"}>
                  “Client Data”
                </Box>
              </Box>{" "}
              <Box component={"span"}>
                means any Client data, information, or materials provided by
                Client or any Authorized User or otherwise transmitted from a
                Third-Party Product in connection with Client’s use of the
                Service.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title} component={"span"}>
                14.4{" "}
                <Box sx={{ fontWeight: "bold" }} component={"span"}>
                  “Documentation”
                </Box>
              </Box>{" "}
              <Box component={"span"}>
                means any online guides, instructions, demos, manuals, and other
                similar documentation PartnerElement provides or makes available
                from time to time on its web site, within the Service, or
                otherwise.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title} component={"span"}>
                14.5{" "}
                <Box sx={{ fontWeight: "bold" }} component={"span"}>
                  “Effective Date”
                </Box>
              </Box>{" "}
              <Box component={"span"}>
                means the date set forth in an Order Form or any earlier date on
                which Client enters into another contract that references these
                Terms, or takes an action online to accept these Terms when
                requested in connection with obtaining Service from us (as
                applicable and whichever first occurs).
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title} component={"span"}>
                14.6{" "}
                <Box sx={{ fontWeight: "bold" }} component={"span"}>
                  “Service Term”
                </Box>
              </Box>{" "}
              <Box component={"span"}>
                means that period of time during which Client’s Authorized Users
                are entitled to access and use the Service, commencing upon the
                Effective Date and continuing for the duration of the trial or
                subscription as indicated on the Order Form including, when
                applicable, any renewal of such trial or subscription period,
                subject in any event to earlier termination as set forth herein.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title} component={"span"}>
                14.7{" "}
                <Box sx={{ fontWeight: "bold" }} component={"span"}>
                  “Service”
                </Box>
              </Box>{" "}
              <Box component={"span"}>
                means the online hosted platform and related services provided
                to Client by PartnerElement that may be more specifically
                described in an Order Form referencing these Terms, including,
                without limitation, all software, information, and
                Documentation, and all updates, modifications, and enhancements
                thereto that may hereafter be made available by PartnerElement.
              </Box>
            </Box>
            <Box sx={styles.title}>15. Miscellaneous</Box>
            <Box>
              <Box sx={styles.title}>15.1 Waivers</Box>
              <Box>
                No failure or delay by either party in exercising any right
                under this Agreement will constitute a waiver of that right. No
                waiver by either Client or PartnerElement of any breach or
                default or failure to exercise any right allowed under the
                Agreement is a waiver of any proceeding or subsequent breach or
                default or a waiver or forfeiture of any similar or future
                rights under the Agreement. The section headings used herein are
                for convenience only and shall be of no legal force or effect.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>15.2 Publicity</Box>
              <Box>
                PartnerElement may use Client’s name, logo, and trademarks
                solely to identify Client as a client of PartnerElement on
                PartnerElement’s website and other marketing materials and in
                accordance with Client’s trademark usage guidelines (assuming
                Client provides said guidelines to PartnerElement) unless Client
                provides notice to PartnerElement that such use is not
                permitted.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>15.3 Assignment</Box>
              <Box>
                Neither party may assign any of its rights under this Agreement
                or delegate its performance under this Agreement without the
                prior written consent of the other party; except that either may
                assign its rights and delegate its performance under this
                Agreement to: (i) any entity that acquires all or substantially
                all of its assets; (ii) any Affiliate that controls, is
                controlled by, or is under common control with the party; and
                (iii) any successor in a merger, acquisition, or reorganization,
                including any judicial reorganization; provided that, in each
                instance, notice of such assignment is promptly given and all
                Fees owed and due have been paid and the assignee agrees to be
                bound by all the terms of this Agreement.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>15.4 Governing Law</Box>
              <Box>
                This Agreement and any disputes arising out of or related hereto
                shall be governed by and construed in accordance with the laws
                of the State of California, without giving effect to its
                conflicts of laws rules or the United Nations Convention on the
                International Sale of Goods. Subject to the dispute resolution
                provisions in Section 13 of these Terms, the parties consent to
                the personal and exclusive jurisdiction of the federal and state
                courts of San Francisco, California.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>15.5 Force Majeure</Box>
              <Box>
                If the performance of this Agreement or any obligation hereunder
                (other than obligations of payment) is prevented or restricted
                by reasons beyond the reasonable control of a party including
                but not limited to act of nature, act of God, fire, casualty,
                flood, war, failure of public utilities, injunction or any act,
                exercise, assertion or requirement of any governmental
                authority, computer related attacks, hacking,epidemic, pandemic
                or acts of terrorism (a{" "}
                <Box component={"span"} sx={{ fontWeight: "bold" }}>
                  “Force Majeure Event”
                </Box>
                ), the party so affected shall be excused from such performance
                and liability to the extent of such prevention or restriction
                and, if such party has used reasonable efforts to avoid such
                occurrence and minimize its duration and has given prompt
                written notice to the other party, then such party’s failure to
                perform shall be excused and the time for performance shall be
                extended for the period of delay or inability to perform due to
                such occurrence; provided, however, if any Force Majeure Event
                continues to prevent performance continuously for ninety (90)
                days, the party whose performance has not been affected thereby
                may elect to terminate the Service Term by notice to the other
                party.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>15.6 Independent Contractors</Box>
              <Box>
                The parties shall be independent contractors under this
                Agreement, and nothing herein shall constitute either party as
                the employer, employee, agent, or representative of the other
                party, or both parties as joint venturers or partners for any
                purpose.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>15.7 Anti-Corruption</Box>
              <Box>
                Client agrees that it has not received or been offered any
                illegal or improper bribe, kickback, payment, gift, or thing of
                value from any of PartnerElement employees or agents in
                connection with this Agreement. Reasonable gifts and
                entertainment provided in the ordinary course of business do not
                violate the above restriction. If Client learns of any violation
                of the above restriction, Client will use reasonable efforts to
                promptly notify PartnerElement.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>15.8 Entire Agreement</Box>
              <Box>
                These Terms (together with each applicable Order Form and, if
                applicable, the Data Processing Addendum) constitute the entire
                agreement between the parties hereto pertaining to the subject
                matter hereof. If any provision of this Agreement is held to be
                invalid or unenforceable, such provision shall be modified to
                reflect the parties’ intent to the extent permissible, and the
                other provisions herein shall remain in full force and effect.
                In the event of any conflict or inconsistency among the
                following documents, the order of precedence shall be: (1) the
                applicable Order Form, (2) the Data Processing Addendum (with
                respect to its specific subject matter); and (3) the terms set
                forth in this document.
              </Box>
            </Box>
            <Box>
              <Box sx={styles.title}>15.9 Modifications</Box>
              <Box>
                We may modify these Terms at any time, in our sole discretion,
                as new features, technology, or legal requirements arise, so
                please check back from time to time. You may always determine if
                the Terms have changed by checking the Publication Date at the
                top of these Terms. If we modify these Terms in a manner that
                materially adversely affects your rights or obligations under
                these Terms we will provide notice to you either by emailing the
                email associated with your account or providing an in-product
                notification, and such changes will become effective no sooner
                than 30 days after we notify you. All other changes to these
                Terms will be effective immediately. If you decide that you do
                not wish to accept the updated terms that materially adversely
                affect your rights or obligations herein, you may, by delivering
                notice to PartnerElement within thirty (30) days after the
                notification of the modified Terms, elect to terminate the
                Service Term as of the later of the effective date of the
                modified Terms or the date specified in your notice; your
                continued use of the Service after the effectiveness of that
                update will be deemed to represent your agreement with, and
                consent to be bound by, the new Terms. Except for changes made
                by us as described here, no other amendment or modification of
                these Terms shall be effective unless set forth in a written
                agreement expressly amending these Terms and bearing a written
                signature by you and us. For clarity, email or other
                communications will not constitute an effective written
                agreement for this purpose.
              </Box>
            </Box>
            <Box sx={{ fontStyle: "italic" }}>
              Please note that “Stripe” is a trademark of Stripe, Inc.,
              respectively, and or its affiliates in the U.S. and other
              countries, and other trademarks and service marks referenced
              herein are the trademarks of their respective owners.
              PartnerElement does not endorse any of these services, and neither
              Stripe nor any other services mentioned in these Terms should be
              interpreted as an endorsement or recommendation of any
              PartnerElement service, and no assumption of any endorsement
              should be taken from PartnerElement’s integration with or use of
              any of those services.
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
