import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Box, Stack, useTheme } from "@mui/material";

import RequestDemo from "../components/popups/requestDemo";
import StyledButton from "../components/styled/styledButton";

import { isMobileDevice } from "../utils/deviceUtils";

const Home = () => {
  const theme = useTheme();

  const [isOpenRequestDemo, setIsOpenRequestDemo] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsMobile(isMobileDevice(userAgent));
  }, []);

  return (
    <>
      <Helmet>
        <title>Partner Element</title>
        <link rel="icon" href="/favicon.ico" sizes="any" />
      </Helmet>
      <Box
        sx={{
          position: "relative",
          justifyContent: "center",
          height: "fit-content",
          maxHeight: "100vh",
          width: "100%",
          "@media (max-height: 680px)": {
            width: "100%",
            maxHeight: "680px",
            height: "680px",
          },
        }}
      >
        <Box
          sx={{
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100% ",
            height: "100%",
            padding: "11.2% 10.8% 0 10.8%",
            boxSizing: "border-box",

            "@media (max-width: 1240px)": {
              width: "100%",
              padding: "7.84% 4.3% 0 4.3%",
              height: "100%",
            },
            "@media (max-width: 800px)": {
              width: "100%",
              padding: "125px 4.3% 0 4.3%",
              height: "100%",
            },
            "@media (max-width: 600px)": {
              width: "100%",
              padding: "35% 4.3% 0 4.3%",
              height: "calc(100vh - 64px)",
              "@media (max-height: 680px)": {
                maxHeight: "680px",
                height: "680px",
              },
            },
          }}
        >
          <Box
            sx={{
              fontFamily: "Coil",
              fontSize: isMobile ? "40px " : "80px",
              fontWeight: "400",
              lineHeight: isMobile ? "40px " : "80px",
              letterSpacing: "-2.4px",
              color: theme.palette.text.offWhite,
              marginBottom: "37px",
              "@media (max-width: 800px)": {
                fontSize: isMobile ? "40px " : "60px",
                lineHeight: isMobile ? "40px " : "60px",
                letterSpacing: "-1.8px",
              },
              "@media (max-width: 600px)": {
                fontSize: "40px",
                lineHeight: "40px",
                letterSpacing: "-1.2px",
              },
              "@media (max-width: 400px)": {
                paddingRight: "10%",
              },
            }}
          >
            <Box>Contextual intelligence</Box>
            <Box>for corporate buyers</Box>
          </Box>
          <Box
            sx={{
              width: "880px",
              fontSize: isMobile ? "32px " : "40px",
              fontWeight: " 400",
              lineHeight: "50px",
              letterSpacing: "-0.4px",
              color: theme.palette.text.offWhite,
              marginBottom: "80px",
              "@media (max-width: 1024px)": {
                width: "536px",
                fontSize: "36px",
                lineHeight: "40px",
              },
              "@media (max-width: 800px)": {
                width: "536px",
                fontSize: isMobile ? "32px " : "36px",
                lineHeight: "40px",
              },
              "@media (max-width: 600px)": {
                width: "100%",
                fontSize: "32px",
                lineHeight: "40px",
              },
            }}
          >
            Leverage your company’s collective knowledge to maximize purchasing speed, compliance, and value
          </Box>
          <StyledButton
            onClick={() => setIsOpenRequestDemo(true)}
            sx={{
              padding: "16px 30px",
              height: "48px",
              borderRadius: "5px",
              fontWeight: "400",
              fontSize: "18px",
              width: isMobile ? "100%" : "fit-content",

              "@media (max-width: 940px)": {
                width: isMobile ? "calc(100% - 8.6%)" : "fit-content",
                position: "absolute",
                top: "calc(100% - 212px)",
              },
              "@media (max-width: 600px)": {
                top: "calc(100% - 132px)",
              }
            }}
            color={"buttonGrey"}
            label={"Request demo"}
            variant={"contained"}
          />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            overflow: "hidden",
            width: "100%",
            height: "fit-content",
          }}
        >
          <Box
            component={"video"}
            autoPlay
            loop
            muted
            playsInline
            sx={{
              position: "relative",
              top: "0",
              left: "0",
              height: "auto",
              maxHeight: "100vh",
              width: "100%",
              objectFit: "fill",
              "@media (max-height: 680px)": {
                width: "100%",
                maxHeight: "680px",
                height: "680px",
              },
              "@media (max-width: 940px)": {
                width: "auto",
                maxHeight: "calc(100vh - 64px)",
                height: "792px",
                "@media (max-height: 680px)": { maxHeight: "680px" },
              },
            }}
          >
            <source src="/animation.mp4" type="video/mp4" />
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            padding: "73px 10.8% 0 10.8%",
            "@media (max-width: 1240px)": {
              padding: "73px 4.3% 0 4.3%",
            },
          }}
        >
          <Box
            sx={{
              color: theme.palette.text.neutral,
              fontFamily: "Coil",
              fontSize: "80px",
              fontWeight: "400",
              lineHeight: "80px",
              letterSpacing: "-2.4px",
              marginBottom: "64px",
              "@media (max-width: 800px)": {
                fontSize: "60px",
                lineHeight: "60px",
                letterSpacing: "-1.8px",
              },
              "@media (max-width: 600px)": {
                fontSize: "40px",
                lineHeight: "40px",
                letterSpacing: "-1.2px",
              },
            }}
          >
            {isMobile ? (
              <>
                <Box>The Partner</Box>
                <Box>Element approach</Box>
              </>
            ) : (
              <>
                <Box>The Partner Element</Box>
                <Box>approach</Box>
              </>
            )}
          </Box>
          {isMobile ? (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              marginBottom={"80px"}
              gap={"58px"}
              sx={{
                "@media (max-width: 1300px)": {
                  flexWrap: "wrap",
                },
              }}
            >
              <Box>
                <Stack direction={"row"} gap={"25px"} marginBottom={"28px"}>
                  <Box
                    sx={{
                      width: "96px",
                      height: "96px",
                      marginBottom: "26px",
                    }}
                  >
                    <img src="/arrows.svg" alt="img" />
                  </Box>
                  <Box
                    sx={{
                      fontSize: "24px",
                      fontWeight: "400",
                      lineHeight: "30px",
                      color: theme.palette.text.primary,
                    }}
                  >
                    <Box>Real-time view of new deals</Box>
                    <Box>and renewals</Box>
                  </Box>
                </Stack>

                <Box>
                  {[
                    "4–6 weeks heads-up on deals",
                    "Accurate pipeline planning",
                    "High employee satisfaction",
                  ].map((item, index) => (
                    <Stack
                      key={index}
                      direction={"row"}
                      alignItems={"start"}
                      gap={"8px"}
                    >
                      <Box
                        sx={{
                          width: "12px",
                          height: "12px",
                          position: "relative",
                          top: "4px",
                        }}
                      >
                        <img src="/sparkle.svg" alt="icon" />
                      </Box>
                      <Box
                        sx={{
                          fontSize: "18px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: theme.palette.text.neutral,
                        }}
                      >
                        {item}
                      </Box>
                    </Stack>
                  ))}
                </Box>
              </Box>
              <Box>
                <Stack direction={"row"} gap={"25px"} marginBottom={"28px"}>
                  <Box
                    sx={{
                      width: "96px",
                      height: "96px",
                      marginBottom: "26px",
                    }}
                  >
                    <img src="/people.svg" alt="img" />
                  </Box>
                  <Box
                    sx={{
                      fontSize: "24px",
                      fontWeight: "400",
                      lineHeight: "30px",
                      color: theme.palette.text.primary,
                    }}
                  >
                    <Box>Supplier profiles with complete</Box>
                    <Box>relationships</Box>
                  </Box>
                </Stack>
                <Box>
                  {[
                    "Supplier contacts, with titles and phone numbers",
                    "Employees interacting with each supplier",
                    "Company information, such as size and revenue, out-of-the-box",
                  ].map((item, index) => (
                    <Stack
                      key={index}
                      direction={"row"}
                      alignItems={"start"}
                      gap={"8px"}
                    >
                      <Box
                        sx={{
                          width: "12px",
                          height: "12px",
                          position: "relative",
                          top: "4px",
                        }}
                      >
                        <img src="/sparkle.svg" alt="icon" />
                      </Box>
                      <Box
                        sx={{
                          fontSize: "18px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: theme.palette.text.neutral,
                        }}
                      >
                        {item}
                      </Box>
                    </Stack>
                  ))}
                </Box>
              </Box>
            </Stack>
          ) : (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              marginBottom={"80px"}
              gap={"40px"}
              sx={{
                "@media (max-width: 600px)": {
                  flexWrap: "wrap",
                },
              }}
            >
              <Box>
                <Box
                  sx={{ width: "96px", height: "96px", marginBottom: "26px" }}
                >
                  <img src="/arrows.svg" alt="img" />
                </Box>
                <Box
                  sx={{
                    fontSize: "32px",
                    fontWeight: "400",
                    lineHeight: "40px",
                    color: theme.palette.text.primary,
                    marginBottom: "28px",
                  }}
                >
                  <Box>Real-time view of new deals</Box>
                  <Box>and renewals</Box>
                </Box>
                <Box>
                  {[
                    "4–6 weeks heads-up on deals",
                    "Accurate pipeline planning",
                    "High employee satisfaction",
                  ].map((item, index) => (
                    <Stack
                      key={index}
                      direction={"row"}
                      alignItems={"start"}
                      gap={"8px"}
                    >
                      <Box
                        sx={{
                          width: "12px",
                          height: "12px",
                          position: "relative",
                          top: "4px",
                        }}
                      >
                        <img src="/sparkle.svg" alt="icon" />
                      </Box>
                      <Box
                        sx={{
                          fontSize: "18px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: theme.palette.text.neutral,
                        }}
                      >
                        {item}
                      </Box>
                    </Stack>
                  ))}
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{ width: "96px", height: "96px", marginBottom: "26px" }}
                >
                  <img src="/people.svg" alt="img" />
                </Box>
                <Box
                  sx={{
                    fontSize: "32px",
                    fontWeight: "400",
                    lineHeight: "40px",
                    color: theme.palette.text.primary,
                    marginBottom: "28px",
                  }}
                >
                  <Box>Supplier profiles with complete</Box>
                  <Box>relationships</Box>
                </Box>
                <Box>
                  {[
                    "Supplier contacts, with titles and phone numbers",
                    "Employees interacting with each supplier",
                    "Company information, such as size and revenue, out-of-the-box",
                  ].map((item, index) => (
                    <Stack
                      key={index}
                      direction={"row"}
                      alignItems={"start"}
                      gap={"8px"}
                    >
                      <Box
                        sx={{
                          width: "12px",
                          height: "12px",
                          position: "relative",
                          top: "4px",
                        }}
                      >
                        <img src="/sparkle.svg" alt="icon" />
                      </Box>
                      <Box
                        sx={{
                          fontSize: "18px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: theme.palette.text.neutral,
                        }}
                      >
                        {item}
                      </Box>
                    </Stack>
                  ))}
                </Box>
              </Box>
            </Stack>
          )}
          <StyledButton
            onClick={() => setIsOpenRequestDemo(true)}
            sx={{
              padding: "16px 30px",
              borderRadius: "5px",
              fontWeight: "400",
              fontSize: "18px",
              height: "48px",
              marginBottom: "120px",
              width: isMobile ? "100%" : "fit-content",
            }}
            variant={"contained"}
            color={"buttonPrimary"}
            label={"Request demo"}
          />
        </Box>
      </Box>
      <RequestDemo
        isOpen={isOpenRequestDemo}
        close={() => setIsOpenRequestDemo(false)}
      />
    </>
  );
};
export default Home;
