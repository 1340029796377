import React from "react";

import { Box, Button, Popover, Stack, useTheme } from "@mui/material";

const Cookies = ({ isOpen, close }) => {
  const theme = useTheme();

  const handleDecline = () => {
    close(false);
  };

  const handleAccept = () => {
    close(true);
  };

  return (
    <Popover
      disableScrollLock={true}
      PaperProps={{
        sx: {
          background: "#DADAEF",
          borderRadius: "5px",
        },
      }}
      open={isOpen}
      onClose={() => null}
      anchorReference={"anchorPosition"}
      anchorPosition={{
        top: typeof window !== "undefined" ? (window.innerHeight / 2) * 2 : 0,
        left: typeof window !== "undefined" ? window.innerWidth / 2 - 276 : 0,
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-around"}
        flexWrap={"wrap"}
        gap={"5px 185px"}
        sx={{
          padding: "16px 24px",
          fontFamily: "CoFo Sans",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "24px",
            color: theme.palette.text.neutral,
          }}
        >
          <Box component={"span"}>
            With your consent, we may use cookies to improve user experience and
            analyze website traffic.
          </Box>
          <Box component={"span"}>
            To learn more about it, see our{" "}
            <a style={{ color: theme.palette.text.neutral }} href="/privacy">
              privacy policy
            </a>
            .
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            gap={"120px"}
            marginTop={"16px"}
          >
            <Button
              onClick={handleDecline}
              variant={"text"}
              sx={{
                color: theme.palette.primary.medium,
                fontSize: "16px",
                lineHeight: "20px",
                padding: "0px",
              }}
            >
              Decline
            </Button>
            <Button
              onClick={handleAccept}
              variant={"text"}
              sx={{
                color: theme.palette.primary.medium,
                fontSize: "16px",
                lineHeight: "20px",
                padding: "0px",
              }}
            >
              Accept
            </Button>
          </Box>
        </Box>
      </Stack>
    </Popover>
  );
};

export default Cookies;
